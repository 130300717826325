const state = {
  shoppingCartItems: localStorage.shoppingCartItems
    ? JSON.parse(localStorage.shoppingCartItems)
    : [],
  shoppingCartDiscountCode: localStorage.shoppingCartDiscountCode
    ? JSON.parse(localStorage.shoppingCartDiscountCode)
    : {},
  depositApplicationID: localStorage.depositApplicationID
    ? JSON.parse(localStorage.depositApplicationID)
    : 0,
};

const getters = {
  getCartItems: (state) => state.shoppingCartItems,
  getCartDiscountCode: (state) => state.shoppingCartDiscountCode,
  getDepositApplicationID: (state) => state.depositApplicationID,
};

const mutations = {
  addWebItemToCart(state, payload, quantity) {
    payload.quantity = quantity ? quantity : 1;
    var itemID = payload.webItemID;
    var matchingItem = state.shoppingCartItems.find((item) => {
      return item.webItemID === itemID;
    });
    if (matchingItem) {
      matchingItem.quantity = matchingItem.quantity + 1;
      if (matchingItem.maxQty && matchingItem.quantity > matchingItem.maxQty) {
        matchingItem.quantity = matchingItem.maxQty;
      }
    } else {
      state.shoppingCartItems.push(payload);
    }
    localStorage.setItem("shoppingCartItems", JSON.stringify(state.shoppingCartItems));
  },
  updateDepositApplicationID(state, payload) {
    state.depositApplicationID = payload;
    localStorage.setItem("depositApplicationID", payload);
  },
  addCouponCodeDiscountToCart(state, payload) {
    state.shoppingCartDiscountCode = payload;
    localStorage.setItem(
      "shoppingCartDiscountCode",
      JSON.stringify(state.shoppingCartDiscountCode)
    );
  },
  removeCouponCodeDiscountFromCart() {
    state.shoppingCartDiscountCode = {};
    localStorage.removeItem("shoppingCartDiscountCode");
  },
  removeWebItemFromCart(state, webItemID) {
    var matchingItemIndex = state.shoppingCartItems.findIndex((x) => x.webItemID == webItemID);
    if (matchingItemIndex > -1) {
      state.shoppingCartItems.splice(matchingItemIndex, 1);
      localStorage.setItem("shoppingCartItems", JSON.stringify(state.shoppingCartItems));
    }
  },
  clearShoppingCart() {
    localStorage.removeItem("shoppingCartItems");
    state.shoppingCartItems = [];
    localStorage.removeItem("shoppingCartDiscountCode");
    state.shoppingCartDiscountCode = {};
    localStorage.removeItem("depositApplicationID");
    state.depositApplicationID = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
