<template>
  <div class="logincontainer flex">
    <div id="loginheader" class="loginheader">
      <h2>{{ $t("header.login") }}</h2>
    </div>
    <messages />
    <RegisterModal ref="RegisterModal"></RegisterModal>
    <form id="loginfrm">
      <div class="loginfrmcontainer flex">
        <div class="loginbox roundeddiv">
          <div class="loginfrm">
            <b-form class="av-tooltip tooltip-label-bottom">
              <b-form-group label="Email" class="has-float-label mb-2">
                <b-form-input
                  type="email"
                  v-model="$v.email.$model"
                  :state="validateProperty('email')"
                  @keydown.enter="formSubmit"
                />
              </b-form-group>

              <b-form-group label="Password" class="has-float-label mb-2">
                <b-input-group class="mt-3">
                  <template #append>
                    <b-input-group-text @click="toggleShow"
                      ><strong class="text">
                        <b-icon
                          :icon="showPassword ? 'eye-slash-fill' : 'eye-fill'"
                          aria-hidden="true"
                        ></b-icon> </strong
                    ></b-input-group-text>
                  </template>
                  <b-form-input
                    :type="showPassword ? 'text' : 'password'"
                    v-model="$v.password.$model"
                    :state="validateProperty('password')"
                    @keydown.enter="formSubmit"
                  />
                </b-input-group>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!$v.password.required && $v.password.$dirty"
                  >Please enter your password</b-form-invalid-feedback
                >
                <b-form-invalid-feedback class="d-block" v-else-if="!$v.password.minLength"
                  >Your password must be at least 12 characters.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback class="d-block" v-else-if="!$v.password.maxLength"
                  >Your password should not be 15 characters.</b-form-invalid-feedback
                >
              </b-form-group>
              <div class="frgtpassword">
                <a class="loginlinks" @click="goToForgotPassword"
                  >{{ $t("question.forgot-password") }}
                </a>
              </div>
              <div class="loginbtnbg">
                <api-button-overlay :processing="processing">
                  <b-button class="login-blue" :disabled="$v.$invalid" @click="formSubmit">
                    <span class="label">{{ $t("button.login") }}</span>
                  </b-button>
                </api-button-overlay>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { adminRoot } from "../../constants/config";
import UserMixin from "../../mixins/UserMixin.vue";
import { mutations } from "../../store/Login";
import ModalMessages from "../../containers/navs/ModalMessages.vue";
import RegisterModal from "../../components/Login/RegisterModal.vue";
import { bus } from "../../main";

const { required, maxLength, minLength } = require("vuelidate/lib/validators");

export default {
  props: ["openCompanyModal"],
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },
  mixins: [validationMixin, UserMixin],
  components: {
    messages: ModalMessages,
    RegisterModal: RegisterModal,
  },
  validations: {
    email: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(12),
    },
  },
  computed: {
    // ...mapGetters(["currentUser", "loginError"]),
  },
  methods: {
    async formSubmit() {
      bus.$emit("showFullpageOverlayLayout", {
        showLayover: true,
      });
      if (!this.$v.$anyError) {
        await this.login({
          email: this.email,
          password: this.password,
        });
        if (this.statusCode && this.statusCode === 200) {
          bus.$emit("showFullpageOverlayLayout", {
            showLayover: false,
          });
          switch (this.status) {
            case "success":
              this.email = "";
              this.password = "";
              this.$v.$reset();
              this.openCompanyModal();
              break;
            case "import":
              var {
                email = "",
                firstname = "",
                lastname = "",
              } = JSON.parse(localStorage.getItem("loggedUserData"));
              this.$refs.RegisterModal.show({
                registerDetails: {
                  email,
                  firstName: firstname,
                  lastName: lastname,
                  cclogin: email,
                },
                exitFunction: () => {
                  //window.location.reload();
                },
              });
              this.email = "";
              this.password = "";
              this.$v.$reset();
              break;
            default:
              break;
          }
        }
      }
    },
    validateProperty(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    goToForgotPassword() {
      mutations.toggleLogin();

      mutations.toggleForgotPassword();
    },
    goToResendVerificationEmail() {
      mutations.toggleLogin();

      mutations.toggleResendVerificationEmail();
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    currentUser(val) {
      if (val && val.uid && val.uid.length > 0) {
        this.$router.push(adminRoot);
      }
    },
  },
};
</script>
