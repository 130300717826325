<template>
  <b-modal
    id="register-modal"
    ref="register-modal"
    :title="$t('header.verifying-email')"
    size="lg"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />
    <p v-if="statusCode != 200" class="mb-0 logintext">
      {{ $t("question.having-trouble") }}
      <a @click="goToResendVerificationEmail" class="loginlinks">{{
        $t("customer.try-resending-your-verification-email")
      }}</a>
    </p>
    <div v-if="statusCode == 200" class="mt-3">
      <div class="loginbtnbg">
        <!-- <b-link tag="a" @click="close" class="loginlinks">{{ $t("generic.go-to-home-page") }}</b-link> -->
        <api-button-overlay :processing="processing">
          <b-button class="btn btn-blue" v-b-modal.modal-center @click="close"
            ><span class="label">{{ $t("button.login") }}</span></b-button
          >
        </api-button-overlay>
      </div>
    </div>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>
<script>
import UserMixin from "../../mixins/UserMixin.vue";
import { mapMutations } from "vuex";
import ModalMessages from "../../containers/navs/ModalMessages.vue";
//import { mutations } from "../../store/Login";

export default {
  data() {
    return {
      isVisible: false,
      email: null,
      token: null,
    };
  },
  mixins: [UserMixin],
  components: {
    messages: ModalMessages,
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    async show(opts = {}) {
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      this.email = opts.email;
      this.token = opts.token;

      this.isVisible = true;

      if (this.email != null && this.token != null) {
        await this.verifyEmail({
          email: this.email,
          token: this.token,
        });
      } else {
        this.setModalDangerMessage("Invalid URL. Cannot verify email address.");
      }
    },
    close() {
      history.replaceState(null, null, "?");

      if (this.exitFunction) {
        this.exitFunction();
      }
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    goToResendVerificationEmail() {
      this.$router.push({
        path: "/",
        query: { module: "resend-verification-email" },
      });
    },
  },
};
</script>
