<template>
  <full-page-overlay :processing="processing" :showWaiting="true" :waitingText="waitingText">
    <div class="wrapper">
      <dirty-data-modal ref="dirtyDataModal" />
      <top-nav />
      <app-nav-bar />
      <main>
        <b-card class="dashboardBackgroundCard shadow-sm">
          <messages />
          <b-row>
            <!-- Sidebar menu options -->
            <b-col sm="12" lg="4" xl="3">
              <sidebar-menu></sidebar-menu>
            </b-col>
            <b-col sm="12" lg="8" xl="9">
              <slot></slot>
            </b-col>
          </b-row>
        </b-card>
      </main>
      <asd-app-footer />
    </div>
  </full-page-overlay>
</template>
<style lang="scss" scoped>
main {
  background-color: #e3ecf2;
  height: 100%;
  @media (min-width: 769px) {
    padding: 10px 20px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}
</style>
<script>
import SidebarMenu from "../containers/navs/SidebarMenu.vue";
import Topnav from "../containers/navs/Topnav.vue";
import AppNavbar from "../containers/navs/AppNavbar.vue";
import AppFooter from "../containers/navs/AppFooter.vue";
import Messages from "../containers/navs/Messages";
import DirtyDataModal from "../components/Modals/DirtyDataModal.vue";
import { bus } from "../main";
import { mapMutations } from "vuex";

export default {
  props: [],
  components: {
    "sidebar-menu": SidebarMenu,
    "top-nav": Topnav,
    "app-nav-bar": AppNavbar,
    "asd-app-footer": AppFooter,
    messages: Messages,
    "dirty-data-modal": DirtyDataModal,
  },
  data() {
    return {
      processing: false,
      waitingText: "",
    };
  },
  mounted() {
    bus.$on("popDirtyDataModal", (to, from) => {
      this.popDirtyDataModal(to, from);
    });
    bus.$on("showFullpageOverlay", (layoverObj) => {
      this.processing = layoverObj.showLayover;
      this.waitingText = layoverObj.message;
    });
  },
  beforeDestroy() {
    bus.$off("popDirtyDataModal");
    bus.$off("showFullpageOverlay");
  },
  methods: {
    popDirtyDataModal(to, from) {
      const whiteListedPage = ["Standards", "Resources", "ApplicationInfo", "DidYouKnowEmails"];
      let body = "You are about to leave when you have unsaved changes. Do you wish to exit?";
      const isWhiteListed = whiteListedPage.includes(from.name);
      if (isWhiteListed) {
        body =
          "You have made changes to your selected programs. Would you like to save these changes?";
      }
      this.$refs.dirtyDataModal.show({
        title: "WARNING",
        route: to,
        body: body,
        isWhiteListed: isWhiteListed,
        saveChanges: () => {
          bus.$emit("dirtyModalSaveChanges", to);
        },
      });
    },
    ...mapMutations(["setDangerMessage", "setAlertMessage", "setInfoMessage", "appendInfoMessage"]),
  },
};
</script>
