<template>
  <home-layout>
    <router-view />
  </home-layout>
</template>

<script>
//css styles
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "../assets/css/sass/main.scss";

//our standard front page layout
import Layout from "../layouts/Layout";

export default {
  components: {
    "home-layout": Layout,
  },
};
</script>
