<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config.js";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      notifications: [],
      notification: {},
      notificationConfigureData: [],
      processing: false,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "setModalDangerMessage",
      "setModalInfoMessage",
    ]),
    async getUserNotifications() {
      await axios
        .get(`${WebAPIUrl}/api/notification/table/navigator`)
        .then((result) => {
          this.statusCode = result.status;
          this.notifications = result.data.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async acknowledgeNotification(notification) {
      await axios
        .put(`${WebAPIUrl}/api/notification/navigator`, notification)
        .then((result) => {
          this.statusCode = result.status;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async acknowledgeAllNotificationsForUser(userID) {
      await axios
        .patch(`${WebAPIUrl}/api/notification/navigator/${userID}/all`)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async createNotification(notification) {
      await axios
        .post(`${WebAPIUrl}/api/notification/navigator`, notification)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getNotificationConfigureData(aspNetUserID) {
      this.processing = true;
      await axios
        .get(`${WebAPIUrl}/api/notification/navigator/${aspNetUserID}`)
        .then((result) => {
          this.statusCode = result.status;
          this.notificationConfigureData = result.data.sort(
            (a, b) => parseFloat(a.notificationTypeID) - parseFloat(b.notificationTypeID)
          );
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
          this.setModalDangerMessage(error);
        });
    },
    async setNotificationConfigure(notification) {
      await axios
        .put(`${WebAPIUrl}/api/notification/configuration`, notification)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage("Notification configured successfully");
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
  },
};
</script>
