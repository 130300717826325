const data = [
  {
    to: "Dashboard",
    selected: window.location.href.indexOf("dashboard") > 0,
    icon: require("../assets/img/app/sidebar-home-ico-blue-01.svg"),
    selectedIcon: require("../assets/img/app/sidebar-home-ico-wht-01.svg"),
    label: "Dashboard",
  },
  {
    to: "SelectProgramModal",
    selected: false,
    icon: require("../assets/img/app/sidebar-program-selector-ico-blue.b35f3408.svg"),
    selectedIcon: require("../assets/img/app/sidebar-program-selector-ico-blue.b35f3408.svg"),
    label: "Program Selector",
  },
  {
    to: "Standards",
    selected: window.location.href.indexOf("standards") > 0,
    icon: require("../assets/img/app/sidebar-standards-ico-blue-01.svg"),
    selectedIcon: require("../assets/img/app/sidebar-standards-ico-wht-01.svg"),
    label: "Standards",
  },
  {
    to: "Resources",
    selected: window.location.href.indexOf("resources") > 0,
    icon: require("../assets/img/app/sidebar-resources-ico-blue-01.svg"),
    selectedIcon: require("../assets/img/app/sidebar-resources-ico-wht.svg"),
    label: "Resources",
  },
  {
    to: "CompanyProfile",
    selected: window.location.href.indexOf("companyprofile") > 0,
    icon: require("../assets/img/app/sidebar-company-profile-ico-blue-01.svg"),
    selectedIcon: require("../assets/img/app/sidebar-company-profile-ico-wht.svg"),
    label: "Company Profile",
  },
  {
    to: "Applications",
    selected: window.location.href.indexOf("applications") > 0,
    icon: require("../assets/img/app/sidebar-app-ico-blue-01.svg"),
    selectedIcon: require("../assets/img/app/sidebar-app-ico-wht-01.svg"),
    label: "Applications",
  },
];

export default data;
