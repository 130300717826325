<template>
  <!-- our standard alerts that can be shown on any page -->
  <div>
    <b-alert
      v-model="showAlertMessage"
      variant="success"
      class="rounded"
      v-if="AlertMessage"
      dismissible
      ><span v-html="AlertMessage"></span
    ></b-alert>
    <b-alert
      v-model="showDangerMessage"
      variant="danger"
      class="rounded"
      v-if="DangerMessage"
      dismissible
      ><span v-html="DangerMessage"></span
    ></b-alert>
    <b-alert
      v-model="showWarningMessage"
      variant="warning"
      class="rounded"
      v-if="WarningMessage"
      dismissible
      ><span v-html="WarningMessage"></span
    ></b-alert>
    <b-alert v-model="showInfoMessage" variant="info" class="rounded" v-if="InfoMessage" dismissible
      ><span v-html="InfoMessage"></span
    ></b-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { bus } from "../../main";

export default {
  created() {
    bus.$on("clearmessages", () => {
      if (this.PreserveMessageAfterPush) {
        this.setPreserveMessageAfterPush(false);
      } else {
        this.hideAllMessages();
      }
    });
  },
  methods: {
    ...mapMutations([
      "setShowAlertMessage",
      "setShowDangerMessage",
      "setShowWarningMessage",
      "setShowInfoMessage",
      "hideAllMessages",
      "setPreserveMessageAfterPush",
    ]),
  },
  computed: {
    ...mapGetters({
      AlertMessage: "AlertMessage",
      DangerMessage: "DangerMessage",
      WarningMessage: "WarningMessage",
      InfoMessage: "InfoMessage",
      ShowAlertMessage: "ShowAlertMessage",
      ShowDangerMessage: "ShowDangerMessage",
      ShowWarningMessage: "ShowWarningMessage",
      ShowInfoMessage: "ShowInfoMessage",
      PreserveMessageAfterPush: "PreserveMessageAfterPush",
    }),
    showAlertMessage: {
      get: function () {
        return this.ShowAlertMessage;
      },
      set: function (v) {
        this.setShowAlertMessage(v);
      },
    },
    showDangerMessage: {
      get: function () {
        return this.ShowDangerMessage;
      },
      set: function (v) {
        this.setShowDangerMessage(v);
      },
    },
    showWarningMessage: {
      get: function () {
        return this.ShowWarningMessage;
      },
      set: function (v) {
        this.setShowWarningMessage(v);
      },
    },
    showInfoMessage: {
      get: function () {
        return this.ShowInfoMessage;
      },
      set: function (v) {
        this.setShowInfoMessage(v);
      },
    },
  },
};
</script>
