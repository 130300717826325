<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :no-close-on-backdrop="true"
    @cancel="close()"
  >
    <h3>{{ body }}</h3>
    <template #modal-footer="{ cancel }">
      <b-button size="lg" variant="outline-danger" class="mr-1" @click="discard()">
        Leave and Discard Changes
      </b-button>
      <b-button size="lg" variant="success" class="mr-1" @click="cancel" v-if="!isWhiteListed">
        Cancel
      </b-button>
      <b-button
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveChangesForWhitelisted"
        v-if="isWhiteListed"
      >
        Save Changes
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped></style>

<script>
import DirtyDataMixin from "../../mixins/DirtyDataMixin.vue";

export default {
  name: "dirtyDataModal",
  mixins: [DirtyDataMixin],

  data: () => ({
    title: undefined,
    body: undefined,
    route: undefined,
    isVisible: false,
    isWhiteListed: false,
  }),

  async created() {},

  methods: {
    async show(opts = {}) {
      this.route = opts.route;
      this.title = opts.title;
      this.body = opts.body;
      this.isWhiteListed = opts.isWhiteListed;
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }
      if (opts.saveChanges) {
        this.saveChanges = opts.saveChanges;
      }
      this.isVisible = true;
    },

    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }
      this.isVisible = false;
    },
    cancel() {
      this.close();
    },
    discard() {
      this.setDirtyData(false);
      this.close();

      this.$router.push(this.route.fullPath);
    },
    saveChangesForWhitelisted() {
      this.setDirtyData(false);
      if (this.saveChanges) {
        this.saveChanges();
      }
      this.isVisible = false;
    },
  },
};
</script>
