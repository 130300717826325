import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import App from "../views/app/index.vue";
import { bus } from "../main";
import AuthGuard from "../utils/auth.guard";

import { appRoot } from "../constants/config";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/impersonate",
    name: "Home",
    component: Home,
  },
  {
    path: "/app",
    name: "App",
    component: App,
    redirect: `${appRoot}/dashboard`,
    meta: { loginRequired: true },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/app/Dashboard"),
      },
      {
        path: "company-profile",
        name: "CompanyProfile",
        component: () => import("../views/app/CompanyProfile"),
      },
      {
        path: "applications",
        name: "Applications",
        component: () => import("../views/app/Applications"),
      },
      {
        path: "standards",
        name: "Standards",
        component: () => import("../views/app/Standards"),
      },
      {
        path: "resources",
        name: "Resources",
        component: () => import("../views/app/Resources"),
      },
      {
        path: "user-profile",
        name: "UserProfile",
        component: () => import("../views/app/UserProfile"),
      },
      {
        path: "shopping-cart",
        name: "ShoppingCart",
        component: () => import("../views/app/ShoppingCart"),
      },
      {
        path: "news",
        name: "News",
        redirect: `${appRoot}/news/e-news-sign-up`,
        component: () => import("../views/app/News"),
        children: [
          {
            path: "blog",
            name: "NewsBlog",
            component: () => import("../views/app/News/Blog"),
          },
          {
            path: "achc-today",
            name: "ACHCToday",
            component: () => import("../views/app/News/ACHCToday"),
          },
          {
            path: "did-you-know-emails",
            name: "DidYouKnowEmails",
            component: () => import("../views/app/News/DidYouKnowEmails"),
          },
          {
            path: "pcab-today",
            name: "PCABToday",
            component: () => import("../views/app/News/PCABToday"),
          },
          {
            path: "e-news-sign-up",
            name: "EnewsSignup",
            component: () => import("../views/app/News/ENewsSignUp"),
          },
        ],
      },
      {
        path: "notifications",
        name: "notifications",
        component: () => import("../views/app/Notifications"),
      },
      {
        path: "company",
        redirect: `${appRoot}/company/add-new-company`,
        component: () => import("../views/app/Company/index"),
        children: [
          {
            path: "add-new-company",
            name: "AddNewCompany",
            component: () => import("../views/app/Company/AddNewCompany"),
          },
          {
            path: "manage-contacts",
            name: "ManageContacts",
            component: () => import("../views/app/Company/ManageContacts"),
          },
          /*{
            path: "branch-locations",
            name: "BranchLocations",
            component: () => import("../views/app/Company/BranchLocationGrid"),
          },*/
          {
            path: "additional-sites",
            name: "AdditionalSites",
            component: () => import("../views/app/Company/AdditionalSitesGrid"),
          },
          {
            path: "change-request",
            name: "ChangeRequest",
            component: () => import("../views/app/Company/ChangeRequestForms"),
          },
          {
            path: "upload-document",
            name: "UploadDocument",
            component: () => import("../views/app/Company/UploadDocument"),
          },
          {
            path: "add-additional-site",
            name: "AddAdditionalSite",
            component: () => import("../views/app/Company/AddNewCompany"),
          },
          {
            path: "branch-locations/:companyID",
            name: "EditBranchLocation",
            props: true,
            component: () => import("../views/app/Company/AddEditBranchLocation"),
          },
          {
            path: "branch-locations/add-new-company",
            name: "AddBranchLocation",
            component: () => import("../views/app/Company/AddEditBranchLocation"),
          },
          {
            path: ":companyID/details",
            name: "CompanyInfoTabs",
            props: (r) => ({ companyID: Number(r.params.companyID) }),
            redirect: `${appRoot}/company/details/info`,
            component: () => import("../views/app/Company/CompanyInfoTabs"),
            children: [
              {
                path: "info",
                name: "CompanyInfo",
                props: (r) => ({ companyID: Number(r.params.companyID) }),
                component: () => import("../views/app/Company/CompanyInfo"),
              },
              {
                path: "address",
                name: "CompanyAddress",
                props: (r) => ({ companyID: Number(r.params.companyID) }),
                component: () => import("../views/app/Company/CompanyAddress"),
              },
              {
                path: "contact",
                name: "CompanyContact",
                props: (r) => ({ companyID: Number(r.params.companyID) }),
                component: () => import("../views/app/Company/CompanyContact"),
              },
              {
                path: "phone",
                name: "CompanyPhone",
                props: (r) => ({ companyID: Number(r.params.companyID) }),
                component: () => import("../views/app/Company/CompanyPhone"),
              },
            ],
          },
          {
            path: "application-landing/:applicationID",
            name: "ApplicationLanding",
            props: (r) => ({ applicationID: parseInt(r.params.applicationID) }),
            component: () => import("../views/app/Application/ApplicationLanding"),
          },
          {
            path: ":companyID/application/:applicationID",
            name: "ApplicationTabs",
            props: (r) => ({
              applicationID: parseInt(r.params.applicationID),
              companyID: Number(r.params.companyID),
            }),
            //redirect: `${appRoot}/application/:applicationID`,
            component: () => import("../views/app/Application/ApplicationTabs"),
            children: [
              {
                path: "application/:step",
                name: "ApplicationInfo",
                props: (r) => ({
                  applicationID: parseInt(r.params.applicationID),
                  step: r.params.step,
                }),
                component: () => import("../views/app/Application/Application"),
              },
              {
                path: "payment",
                name: "Payment",
                props: (r) => ({
                  applicationID: parseInt(r.params.applicationID),
                }),
                component: () => import("../views/app/Application/Payment"),
              },
              {
                path: "survey",
                name: "Survey",
                props: (r) => ({
                  applicationID: parseInt(r.params.applicationID),
                }),
                component: () => import("../views/app/Application/Survey"),
              },
              {
                path: "poc",
                name: "POC",
                props: (r) => ({
                  applicationID: parseInt(r.params.applicationID),
                }),
                component: () => import("../views/app/Application/POC"),
              },
              {
                path: "accreditation-and-certification",
                name: "AccreditationCertification",
                props: (r) => ({
                  applicationID: parseInt(r.params.applicationID),
                }),
                component: () => import("../views/app/Application/AccreditationCertification"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(AuthGuard);
router.afterEach((to) => {
  bus.$emit("clearmessages");
  store.commit("changeSelectedMenuItemByRoute", to.name);

  // Scroll to top of page after route change
  window.scrollTo(0, 0);
});

export default router;
