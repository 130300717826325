<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";

export default {
  data() {
    return {
      companyAddresses: [],
      checkoutPhysicalAddress: {},
      checkoutAccountingAddress: {},
      checkoutMailingAddress: {},
      addressTypes: [],
      states: [],
      companyAddress: {},
      processing: false,
      applicationSubmittedCompanyAddress: [],
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeCompanyAddress",
    ]),
    //changeCompanyAddress
    async CompanyAddresses(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/${companyID}/table`)
        .then((result) => {
          const { data = [] } = result.data;
          this.changeCompanyAddress(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    //
    async getCompanyAddresses(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/`, {
          params: { companyID: companyID },
        })
        .then((result) => {
          this.companyAddresses = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async addCompanyAddress(companyAddress) {
      this.processing = true;
      await instance
        .post(`${WebAPIUrl}/api/company-address`, companyAddress)
        .then(() => {
          this.setInfoMessage(
            `Successfully Added ${companyAddress.addressLine1} ${companyAddress.addressLine2}`
          );
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
      this.processing = false;
    },
    async updateCompanyAddress(companyAddress) {
      this.processing = true;
      await axios
        .put(`${WebAPIUrl}/api/company-address/${companyAddress.companyAddressID}`, companyAddress)
        .then(() => {
          this.setInfoMessage(
            `Successfully updated address ${companyAddress.addressLine1} ${companyAddress.addressLine2}`
          );
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
      this.processing = false;
    },
    async deleteCompanyAddress(companyAddressID) {
      await axios
        .delete(`${WebAPIUrl}/api/company-address/${companyAddressID}`)
        .then((result) => {
          this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAddressTypes() {
      await axios
        .get(`${WebAPIUrl}/api/company-address/address-type`)
        .then((result) => {
          this.addressTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getStates() {
      await axios
        .get(`${WebAPIUrl}/api/company-address/state`)
        .then((result) => {
          this.states = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAvailableAddressTypesForAddress(companyAddressID, companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/available-address-types-for-address`, {
          params: { companyAddressID: companyAddressID, companyID: companyID },
        })
        .then((result) => {
          this.addressTypes = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async getAvailableAddressTypesForCompany(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/available-address-types-for-company`, {
          params: { companyID: companyID },
        })
        .then((result) => {
          this.addressTypes = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async getCompanyAddressByCompanyAddressID(companyAddressID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/${companyAddressID}`)
        .then((result) => {
          this.companyAddress = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async setCheckoutCompanyAddresses(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/${companyID}/by-type/2`)
        .then((result) => {
          this.checkoutPhysicalAddress = result.data;
        })
        .catch(() => {});
      await axios
        .get(`${WebAPIUrl}/api/company-address/${companyID}/by-type/1`)
        .then((result) => {
          this.checkoutMailingAddress = result.data;
        })
        .catch(() => {});
      await axios
        .get(`${WebAPIUrl}/api/company-address/${companyID}/by-type/3`)
        .then((result) => {
          this.checkoutAccountingAddress = result.data;
        })
        .catch(() => {});
    },
    async ApplicationSubmittedCompanyAddresses(applicationCompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-address/by-applicationCompanyID/${applicationCompanyID}`)
        .then((result) => {
          const { data = [] } = result;
          this.applicationSubmittedCompanyAddress = data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
