<template>
  <b-modal
    id="reset-password-modal"
    ref="reset-password-modal"
    :title="$t('header.reset-password')"
    size="lg"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />
    <b-form class="av-tooltip tooltip-label-bottom">
      <b-row>
        <b-col>
          <label>{{ $t("customer.new-password") }}</label>
          <b-input-group class="has-float-label mb-2">
            <b-form-input
              :type="passwordFieldType"
              v-model="$v.password.$model"
              :state="validateProperty('password')"
              @keydown.enter="formSubmit"
            />
            <b-input-group-append variant="info" class="eye">
              <font-awesome-icon
                style="margin-top: 2px"
                :icon="['fas', 'eye']"
                @click="switchPasswordVisibility()"
              />
            </b-input-group-append>
            <b-form-invalid-feedback v-if="!$v.password.required"
              >Please enter your password</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.password.minLength"
              >Your password must be at least 12 characters.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.password.valid"
              >Your password is missing one or many of the valid
              requirements.</b-form-invalid-feedback
            >
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>{{ $t("customer.confirm-password") }}</label>
          <b-input-group class="has-float-label mb-4">
            <b-form-input
              :type="passwordConfirmFieldType"
              v-model="$v.confirmPassword.$model"
              :state="validateProperty('confirmPassword')"
              @keydown.enter="formSubmit"
            />
            <b-input-group-append variant="info" class="eye">
              <font-awesome-icon
                style="margin-top: 2px"
                :icon="['fas', 'eye']"
                @click="switchConfirmPasswordVisibility()"
              />
            </b-input-group-append>
            <b-form-invalid-feedback v-if="!$v.confirmPassword.required"
              >Please enter your password again</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.confirmPassword.sameAsPassword">
              Your inputs do not match
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p style="margin: 10px 0px" v-html="passwordRequirements"></p>
        </b-col>
      </b-row>
    </b-form>
    <template slot="modal-footer">
      <b-button @click="goToLogin" v-if="statusCode === 200" class="btn btn-blue"> Login </b-button>
      <api-button-overlay v-else :processing="processing">
        <b-button @click="formSubmit" class="btn btn-blue" :disabled="processing">
          {{ $t("button.reset-password") }}
        </b-button>
      </api-button-overlay>
    </template>
  </b-modal>
</template>
<script>
import { validationMixin } from "vuelidate";
import UserMixin from "../../mixins/UserMixin.vue";
import { mapMutations } from "vuex";

import ModalMessages from "../../containers/navs/ModalMessages.vue";

const { required, minLength, sameAs } = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      isVisible: false,
      email: null,
      token: null,
      password: "",
      confirmPassword: "",
      passwordFieldType: "password",
      passwordConfirmFieldType: "password",
    };
  },
  async created() {
    await this.getPasswordRequirements();
  },
  mixins: [validationMixin, UserMixin],
  validations: {
    password: {
      required,
      minLength: minLength(12),
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
      },
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  components: {
    messages: ModalMessages,
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    show(opts = {}) {
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      this.email = opts.email;
      this.token = opts.token;

      this.isVisible = true;
    },

    close() {
      history.replaceState(null, null, "?");
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async formSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.setModalDangerMessage(
          "An error occured while saving. Please verify that your passwords meet the requirements and try again."
        );
      } else {
        await this.resetPassword({
          email: this.email,
          token: this.token,
          password: this.$v.password.$model,
        });
      }
    },
    validateProperty(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    switchConfirmPasswordVisibility() {
      this.passwordConfirmFieldType =
        this.passwordConfirmFieldType === "password" ? "text" : "password";
    },
    goToLogin() {
      this.close();
      this.exitFunction();
    },
  },
};
</script>
<style>
.eye {
  margin-left: 5px !important;
  margin-top: 7px;
}
</style>
