<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <alert ref="Alert"></alert>
    <b-card class="inquiryfrm" no-body>
      <b-card-body class="inquiryfrm wizard wizard-default">
        <form-wizard :withValidate="true" :topNavDisabled="true" :done="finishClicked">
          <tab
            name="Step 1"
            desc="Contact Information"
            selected="true"
            :validate="validateFirstStep"
          >
            <div class="wizard-basic-step">
              <b-form>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('customer.salutation')">
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step1.salutation"
                        @input="$v.customerInquiry.step1.salutation.$touch"
                        :state="validateCustomerInquiry('salutation', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('customer.first-name')">
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step1.firstName"
                        @input="$v.customerInquiry.step1.firstName.$touch"
                        :state="validateCustomerInquiry('firstName', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('customer.last-name')">
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step1.lastName"
                        @input="$v.customerInquiry.step1.lastName.$touch"
                        :state="validateCustomerInquiry('lastName', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('customer.title')">
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step1.title"
                        @input="$v.customerInquiry.step1.title.$touch"
                        :state="validateCustomerInquiry('title', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('customer.phone-number')">
                      <b-form-input
                        type="tel"
                        v-model="phoneMask"
                        id="contact-phone"
                        @keyup="applyMaskWhileTyping()"
                        @input="$v.customerInquiry.step1.phoneNumber.$touch"
                        :state="validateCustomerInquiry('phoneNumber', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('customer.phone-number-extension')">
                      <b-form-input
                        type="email"
                        v-model="customerInquiry.step1.phoneNumberExtension"
                        @input="$v.customerInquiry.step1.phoneNumberExtension.$touch"
                        :state="validateCustomerInquiry('phoneNumberExtension', 'step1')"
                      />
                    </b-form-group>
                    <div
                      class="text-danger"
                      :state="!$v.customerInquiry.step1.phoneNumberExtension.$error"
                      v-if="!$v.customerInquiry.step1.phoneNumberExtension.maxLength"
                    >
                      Must have at most
                      {{ $v.customerInquiry.step1.phoneNumberExtension.$params.maxLength.max }}
                      digits
                    </div>
                    <div
                      class="text-danger"
                      :state="!$v.customerInquiry.step1.phoneNumberExtension.$error"
                      v-if="!$v.customerInquiry.step1.phoneNumberExtension.numeric"
                    >
                      Must be a number
                    </div>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('customer.email')">
                      <b-form-input
                        type="email"
                        v-model="customerInquiry.step1.emailAddress"
                        @input="$v.customerInquiry.step1.emailAddress.$touch"
                        :state="validateCustomerInquiry('emailAddress', 'step1')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </tab>
          <tab name="Step 2" desc="Company Information" :validate="validateSecondStep">
            <div class="wizard-basic-step">
              <b-form>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group>
                      <LabelWithInfoTootlip
                        :label="$t('company.company-legal-name')"
                        tooltipText="The legal name of your business must match paperwork sent to your state or federal regulatory bodies."
                      />
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step2.companyLegalName"
                        @input="$v.customerInquiry.step2.companyLegalName.$touch"
                        :state="validateCustomerInquiry('companyLegalName', 'step2')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <LabelWithInfoTootlip
                        :label="$t('company.dba-company-name')"
                        tooltipText="The Doing Business As (DBA) name is also referred to as an assumed name, fictitious business name, or trade name."
                      />
                      <b-form-input type="text" v-model="customerInquiry.step2.dbaCompanyName" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="8">
                    <b-form-group :label="$t('address.address-line-1')">
                      <vue-google-autocomplete
                        id="map"
                        ref="autocomplete"
                        classname="form-control"
                        placeholder="Search Here..."
                        v-on:placechanged="getAddressData"
                        v-on:change="handleAddressChange"
                        country="us"
                        required
                      >
                      </vue-google-autocomplete>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.customerInquiry.step2.addressLine_1.required &&
                          $v.customerInquiry.step2.addressLine_1.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group :label="$t('address.address-line-2')">
                      <b-form-input type="text" v-model="customerInquiry.step2.addressLine_2" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('address.city')">
                      <b-form-input type="text" v-model="customerInquiry.step2.city" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('address.state')">
                      <b-form-select
                        class="form-select"
                        v-model="customerInquiry.step2.stateID"
                        :state="validateCustomerInquiry('stateID', 'step2')"
                        :options="states"
                        text-field="stateName"
                        value-field="stateID"
                        @input="$v.customerInquiry.step2.stateID.$touch"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('address.zip')">
                      <b-form-input type="text" v-model="customerInquiry.step2.zip" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <LabelWithInfoTootlip
                        :label="$t('company.number-of-locations')"
                        tooltipText="This information helps ACHC scope your survey and develop a customized price quote. If you have locations in multiple states, please contact customerservice@achc.org or call Toll-free: (855) 937-2242"
                      />
                      <b-form-input
                        type="number"
                        min="0"
                        v-model="customerInquiry.step2.numberofLocations"
                        @input="$v.customerInquiry.step2.numberofLocations.$touch"
                        :state="validateCustomerInquiry('numberofLocations', 'step2')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="12">
                    <b-form-group :label="$t('company.programs-of-interest')">
                      <multiselect
                        v-model="customerInquiry.step2.programsOfInterest"
                        :class="{
                          'multiselect-is-invalid':
                            $v.customerInquiry.step2.programsOfInterest.$error &&
                            $v.customerInquiry.step2.programsOfInterest.$dirty,
                          'multiselect-overflow':
                            customerInquiry.step2.programsOfInterest.length > 2,
                          'multiselect-overflow-more':
                            customerInquiry.step2.programsOfInterest.length > 6,
                        }"
                        :options="groupedPrograms"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :placeholder="$t('dropdown.select-a-program')"
                        track-by="programInitials"
                        group-values="options"
                        group-label="label"
                        label="programDescription"
                        :preselect-first="false"
                        @input="$v.customerInquiry.step2.programsOfInterest.$touch"
                      >
                        <template slot="selection">
                          <span
                            class="multiselect__single"
                            v-if="$v.customerInquiry.step2.programsOfInterest.length && !isOpen"
                          >
                            {{ $v.customerInquiry.step2.programsOfInterest.length }}
                            programs selected
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <span
                            v-if="props.option.$isLabel"
                            class="option__title"
                            :title="props.option.$groupLabel"
                            >{{ props.option.$groupLabel }}</span
                          >
                          <span
                            v-else
                            class="option__title"
                            :title="props.option.programDetailDescription"
                            >{{ props.option.programDescription }}</span
                          >
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.other')"
                      v-if="
                        customerInquiry.step2.programsOfInterest.find(
                          ({ programDescription }) => programDescription === 'Other (OT)'
                        )
                      "
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step2.otherProgramsOfInterest"
                        :placeholder="$t('company.other')"
                        @input="$v.customerInquiry.step2.otherProgramsOfInterest.$touch"
                        :state="validateCustomerInquiry('otherProgramsOfInterest', 'step2')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </tab>
          <tab name="Step 3" desc="Accreditation Information" :validate="validateThirdStep">
            <div class="wizard-basic-step">
              <b-form>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('question.which-best-describes-you')">
                      <b-form-select
                        class="form-select"
                        v-model="customerInquiry.step3.leadTypeID"
                        :state="validateCustomerInquiry('leadTypeID', 'step3')"
                        :options="leadTypes"
                        text-field="description"
                        value-field="id"
                        @input="$v.customerInquiry.step3.leadTypeID.$touch"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      v-if="isLeadTypeOtherVisible"
                      :label="$t('company.describe-other')"
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.leadTypeOther"
                        :placeholder="$t('company.other')"
                        @input="$v.customerInquiry.step3.leadTypeOther.$touch"
                        :state="validateCustomerInquiry('leadTypeOther', 'step3')"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('question.what-timeframe-seeking-accreditation')"
                      v-if="isTimeframeVisible"
                      style="padding-top: 10px"
                    >
                      <b-form-select
                        class="form-select"
                        v-model="customerInquiry.step3.accreditationCompletedByID"
                        :state="validateCustomerInquiry('accreditationCompletedByID', 'step3')"
                        :options="timeframes"
                        text-field="description"
                        value-field="id"
                        @input="$v.customerInquiry.step3.accreditationCompletedByID.$touch"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <LabelWithInfoTootlip
                        :label="$t('question.how-did-you-hear-about-achc')"
                        tooltipText="Consultants that are new to ACHC can select Other and enter your consulting agency name, or select the consulting agency that referred you to ACHC."
                      />
                      <b-form-select
                        :disabled="disableAffiliate"
                        class="form-select"
                        v-model="customerInquiry.step3.leadReferralTypeID"
                        :state="validateCustomerInquiry('leadReferralTypeID', 'step3')"
                        :options="leadReferralTypes"
                        text-field="description"
                        value-field="id"
                        @input="$v.customerInquiry.step3.leadReferralTypeID.$touch"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      v-if="isLeadReferralTypeOtherVisible"
                      :label="$t('company.describe-other')"
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.leadReferralTypeOther"
                        :placeholder="$t('company.other')"
                        @input="$v.customerInquiry.step3.leadReferralTypeOther.$touch"
                        :state="validateCustomerInquiry('leadReferralTypeOther', 'step3')"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.consultant')"
                      v-if="isConsultantVisible"
                      style="padding-top: 10px"
                    >
                      <b-form-select
                        :disabled="disableAffiliate"
                        class="form-select"
                        v-model="customerInquiry.step3.inquiryAffiliateID"
                        :state="validateCustomerInquiry('inquiryAffiliateID', 'step3')"
                        :options="affiliateOptions"
                        @input="$v.customerInquiry.step3.inquiryAffiliateID.$touch"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.other')"
                      v-if="isConsultantsOtherVisible"
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.consultantsOther"
                        @input="$v.customerInquiry.step3.consultantsOther.$touch"
                        :state="validateCustomerInquiry('consultantsOther', 'step3')"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.referral-source')"
                      v-if="isReferralSourceVisible"
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.leadReferralSource"
                        @input="$v.customerInquiry.step3.leadReferralSource.$touch"
                        :state="validateCustomerInquiry('leadReferralSource', 'step3')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="formrow">
                  <b-col md="6">
                    <b-form-group :label="$t('question.affiliated-hospital')">
                      <b-form-select
                        class="form-select"
                        v-model="customerInquiry.step3.hospitalAffiliated"
                        :options="yesNoUnknown"
                        text-field="text"
                        value-field="value"
                        :state="validateCustomerInquiry('hospitalAffiliated', 'step3')"
                        @input="$v.customerInquiry.step3.hospitalAffiliated.$touch"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.hospital-or-health-system-name')"
                      v-if="customerInquiry.step3.hospitalAffiliated === 1"
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.hospitalAffiliatedName"
                        @input="$v.customerInquiry.step3.hospitalAffiliatedName.$touch"
                        :state="validateCustomerInquiry('hospitalAffiliatedName', 'step3')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('question.is-organization-currently-accredited')">
                      <b-form-select
                        class="form-select"
                        v-model="customerInquiry.step3.leadCurrentlyAccredited"
                        :options="yesNoUnknown"
                        text-field="text"
                        value-field="value"
                        :state="validateCustomerInquiry('leadCurrentlyAccredited', 'step3')"
                        @input="$v.customerInquiry.step3.leadCurrentlyAccredited.$touch"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.accreditation-organization')"
                      v-if="customerInquiry.step3.leadCurrentlyAccredited === 1"
                      style="padding-top: 10px; margin-bottom: 1rem"
                    >
                      <multiselect
                        v-model="customerInquiry.step3.leadCurrentAccreditationOrganizationIDs"
                        :options="accreditationOrganizations"
                        track-by="accreditationOrganizationID"
                        label="displayName"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Select Accreditation Organization(s)..."
                        :class="{
                          'multiselect-is-invalid':
                            $v.customerInquiry.step3.leadCurrentAccreditationOrganizationIDs.$error,
                        }"
                      >
                      </multiselect>
                    </b-form-group>
                    <b-form-group
                      :label="$t('company.other')"
                      v-if="
                        customerInquiry.step3.leadCurrentAccreditationOrganizationIDs.includes(11)
                      "
                      style="padding-top: 10px"
                    >
                      <b-form-input
                        type="text"
                        v-model="customerInquiry.step3.leadCurrentAccreditationOrganizationOther"
                        @input="
                          $v.customerInquiry.step3.leadCurrentAccreditationOrganizationOther.$touch
                        "
                        :state="
                          validateCustomerInquiry(
                            'leadCurrentAccreditationOrganizationOther',
                            'step3'
                          )
                        "
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </tab>
        </form-wizard>
      </b-card-body>
    </b-card>
  </div>
</template>
<style lang="scss" scoped>
.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #d02c23 !important;
}
.multiselect-overflow {
  padding-bottom: 30px;
}
.multiselect-overflow {
  padding-bottom: 60px;
}
</style>
<script>
import { bus } from "../../main";
import Confirmation from "../../components/Common/Confirmation.vue";
import Alert from "../../components/Common/alert/Alert.vue";
import FormWizard from "../../components/Wizard/FormWizard";
import Tab from "../../components/Wizard/Tab";
import LabelWithInfoTootlip from "../../components/Common/LabelWithInfoTooltip.vue";
import Multiselect from "vue-multiselect";

import CompanyMixin from "../../mixins/CompanyMixin.vue";
import CompanyAddressMixin from "../../mixins/CompanyAddressMixin.vue";
import AffiliateMixin from "../../mixins/AffiliateMixin.vue";
import AccreditationOrganizationsMixin from "../../mixins/AccreditationOrganizationMixin.vue";
import ProgramMixin from "../../mixins/ProgramMixin.vue";
import StateMixin from "../../mixins/StateMixin.vue";
import { validationMixin } from "vuelidate";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric,
  email,
} = require("vuelidate/lib/validators");
export default {
  components: {
    "form-wizard": FormWizard,
    tab: Tab,
    Multiselect,
    Confirmation,
    Alert,
    LabelWithInfoTootlip: LabelWithInfoTootlip,
    "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  data() {
    return {
      affiliateOptions: [],
      yesNoUnknown: [
        { text: "Unknown", value: -1 },
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      customerInquiry: {
        step1: {
          firstName: "",
          lastName: "",
          salutation: "",
          title: "",
          phoneNumber: "",
          phoneNumberExtension: null,
          emailAddress: "",
        },
        step2: {
          companyLegalName: "",
          stateID: null,
          programsOfInterest: [],
          numberofLocations: "",
          addressLine_1: "",
          addressLine_2: "",
          zip: "",
          city: "",
          dbaCompanyName: "",
          CCSMemberID: null,
          otherProgramsOfInterest: "",
        },
        step3: {
          leadTypeID: null,
          accreditationCompletedByID: null,
          leadReferralTypeID: null,
          consultantsOther: "",
          leadReferralSource: "",
          hospitalAffiliated: "",
          hospitalAffiliatedName: "",
          leadCurrentlyAccredited: "",
          leadCurrentAccreditationOrganizationIDs: [],
          leadCurrentAccreditationOrganizationOther: "",
          leadReferralTypeOther: "",
          leadTypeOther: "",
          inquiryAffiliateID: null,
        },
      },
      disableAffiliate: false,
    };
  },
  async created() {
    await this.getStatesGroupedByType();
    await this.getAllProgramsByType(true);
    await this.getLeadTypes();
    await this.getLeadReferralTypes();
    await this.getAllAffiliates();
    this.mapConsultants();
    await this.getTimeframes();
    await this.getAllActiveAccreditationOrganizations();
  },
  mounted() {
    const { AffiliateID = null, CCSMemberID = null } = this.$route.query;
    if (AffiliateID) {
      this.customerInquiry.step3.inquiryAffiliateID = AffiliateID;
      this.customerInquiry.step3.leadReferralTypeID = 2;
      this.disableAffiliate = true;
    }
    if (CCSMemberID) {
      this.customerInquiry.step2.SalesMemberID = CCSMemberID;
    }
  },
  mixins: [
    validationMixin,
    CompanyMixin,
    CompanyAddressMixin,
    AffiliateMixin,
    AccreditationOrganizationsMixin,
    ProgramMixin,
    StateMixin,
  ],
  validations: {
    customerInquiry: {
      step1: {
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        salutation: {
          minLength: minLength(2),
          maxLength: maxLength(5),
        },
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        phoneNumber: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          numeric,
        },
        phoneNumberExtension: {
          maxLength: maxLength(6),
          numeric,
        },
        emailAddress: {
          required,
          email,
        },
      },
      step2: {
        addressLine_1: {
          required,
        },
        stateID: {
          required,
        },
        companyLegalName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(75),
        },
        programsOfInterest: {
          required,
        },
        numberofLocations: {
          required,
          numeric,
        },
        otherProgramsOfInterest: {
          required: requiredIf(function () {
            return this.customerInquiry.step2.programsOfInterest.find(
              ({ programDescription }) => programDescription === "Other (OT)"
            );
          }),
        },
      },
      step3: {
        leadTypeID: {
          required,
        },
        accreditationCompletedByID: {
          required: requiredIf(function () {
            return (
              this.customerInquiry.step3.leadTypeID === 1 ||
              this.customerInquiry.step3.leadTypeID === 2
            );
          }),
        },
        leadReferralTypeID: {
          required,
        },
        inquiryAffiliateID: {
          required: requiredIf(function () {
            return this.customerInquiry.step3.leadReferralTypeID === 2;
          }),
        },
        consultantsOther: {
          required: requiredIf(function () {
            if (this.customerInquiry.step3.inquiryAffiliateID === null) {
              return false;
            }
            return this.customerInquiry.step3.inquiryAffiliateID.affiliateId === -1;
          }),
          minLength: minLength(2),
          maxLength: maxLength(150),
        },
        leadReferralSource: {
          required: requiredIf(function () {
            return this.customerInquiry.step3.leadReferralTypeID === 7;
          }),
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        hospitalAffiliated: {
          required,
        },
        hospitalAffiliatedName: {
          required: requiredIf(function () {
            return this.customerInquiry.step3.hospitalAffiliated === 1;
          }),
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        leadCurrentlyAccredited: {
          required,
        },
        leadCurrentAccreditationOrganizationIDs: {
          required: requiredIf(function () {
            return this.customerInquiry.step3.leadCurrentlyAccredited === 1;
          }),
        },
        leadCurrentAccreditationOrganizationOther: {
          required: requiredIf(function () {
            return this.customerInquiry.step3.leadCurrentAccreditationOrganizationIDs.includes(11);
          }),
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        leadTypeOther: {
          required: requiredIf(function () {
            if (this.customerInquiry.step3.leadTypeID) {
              return this.customerInquiry.step3.leadTypeID === 6;
            } else {
              return false;
            }
          }),
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        leadReferralTypeOther: {
          required: requiredIf(function () {
            if (
              this.customerInquiry.step3.leadReferralTypeID === null ||
              this.customerInquiry.step3.leadReferralTypeID === undefined ||
              this.customerInquiry.step3.leadReferralTypeID === ""
            ) {
              return false;
            }
            return this.customerInquiry.step3.leadReferralTypeID === 28;
          }),
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
      },
    },
  },
  methods: {
    validateFirstStep() {
      this.$v.customerInquiry.step1.$touch();
      if (this.$v.customerInquiry.step1.$error) {
        return false;
      }
      return true;
    },
    validateSecondStep() {
      this.$v.customerInquiry.step2.$touch();
      if (this.$v.customerInquiry.step2.$error) {
        return false;
      }
      return true;
    },
    validateThirdStep() {
      this.$v.customerInquiry.step3.$touch();
      if (this.$v.customerInquiry.step3.$error) {
        return false;
      }
      return true;
    },
    validateCustomerInquiry(name, step) {
      var stepGroup = this.$v.customerInquiry[step];
      const { $dirty, $error } = stepGroup[name];
      return $dirty ? !$error : null;
    },
    async finishClicked() {
      if (this.validateThirdStep()) {
        bus.$emit("wizardProcessing", true);
        await this.submitInquiryForm(this.customerInquiry);
        bus.$emit("wizardProcessing", false);
      }
    },
    async submitInquiryForm(customerInquiry) {
      const { emailAddress: email, firstName, lastName } = _.cloneDeep(customerInquiry.step1);
      await this.submitLeadInquiryForm(customerInquiry);
      if (this.statusCode === 200) {
        this.clearData();
        this.$v.$reset();
        // This sucks
        // But it's temporary since we will be replacing compass in later phases
        if (this.statusMessage.indexOf("Compass") > 0) {
          this.$refs.Alert.show({
            title: "Compass Login",
            message: this.statusMessage,
            okButton: "Okay",
            exitFunction: (e) => {
              if (["headerclose", "esc", "backdrop"].includes(e.trigger)) {
                window.location.reload();
              }
            },
          });
        } else {
          this.$refs.Confirmation.show({
            title: "Navigator Login",
            message: this.statusMessage,
            okButton: "Yes",
            cancelButton: "No",
            exitFunction: (e) => {
              if (["headerclose", "esc", "backdrop"].includes(e.trigger)) {
                window.location.reload();
              }
            },
          }).then(async (result) => {
            if (result) {
              this.$emit("register", { email, firstName, lastName });
            } else {
              window.location.reload();
            }
          });
        }
      }
    },
    setPhoneMask(phone) {
      if (phone === undefined) {
        return "";
      }
      var x = phone
        .toString()
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    applyMaskWhileTyping() {
      var inputValue = document.getElementById("contact-phone").value.toString();
      document.getElementById("contact-phone").value = this.setPhoneMask(inputValue);
    },
    clearData() {
      this.customerInquiry = {
        step1: {
          companyContact: {
            firstName: "",
            lastName: "",
            salutation: "",
            title: "",
            phoneNumber: "",
            phoneNumberExtension: null,
            emailAddress: "",
          },
        },
        step2: {
          companyLegalName: "",
          stateID: null,
          programsOfInterest: [],
          numberofLocations: "",
        },
        step3: {
          leadTypeID: null,
          accreditationCompletedByID: null,
          leadReferralTypeID: null,
          inquiryAffiliateID: null,
          consultantsOther: "",
          leadReferralSource: "",
          hospitalAffiliated: "",
          hospitalAffiliatedName: "",
          leadCurrentlyAccredited: "",
          leadCurrentAccreditationOrganizationIDs: [],
          leadCurrentAccreditationOrganizationOther: "",
          leadReferralTypeOther: "",
          leadTypeOther: "",
        },
      };
    },
    mapConsultants() {
      this.affiliateOptions = this.affiliates.map((c) => {
        return {
          text: c.companyName,
          value: c.affiliateID,
        };
      });
      // Pierson 9/15/21 - I added the push in here instead of in the API
      // so that it would keep the API agnostic and not mess up other GetAll calls
      this.affiliateOptions.push({
        value: -1,
        text: "Other",
      });
    },
    getAddressData: function (addressData) {
      const statesArray = this.states.find((element) => element.label == "State").options;
      const {
        street_number: streetNumber = "",
        route = "",
        locality = "",
        administrative_area_level_1: stateName = "",
        postal_code: postalCode = "",
      } = addressData;
      const stateId = statesArray.find(({ stateCode }) => stateCode === stateName).stateID || "";
      this.customerInquiry = {
        ...this.customerInquiry,
        step2: {
          ...this.customerInquiry.step2,
          addressLine_1: `${streetNumber} ${route}`,
          addressLine_2: "",
          city: locality,
          stateID: stateId,
          zip: postalCode,
        },
      };
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`);
      }, 500);
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update("");
    },
  },
  computed: {
    phoneMask: {
      get: function () {
        return this.setPhoneMask(this.customerInquiry.step1.phoneNumber);
      },
      set: function (v) {
        this.customerInquiry.step1.phoneNumber = v.toString().replace(/\D/g, "");
      },
    },
    isTimeframeVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.leadTypeID === null ||
          this.customerInquiry.step3.leadTypeID === undefined
        ) {
          return false;
        }
        return (
          this.customerInquiry.step3.leadTypeID === 1 || this.customerInquiry.step3.leadTypeID === 2
        );
      },
    },
    isReferralSourceVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.leadReferralTypeID === null ||
          this.customerInquiry.step3.leadReferralTypeID === undefined
        ) {
          return false;
        }
        return this.customerInquiry.step3.leadReferralTypeID === 7;
      },
    },
    isConsultantVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.leadReferralTypeID === null ||
          this.customerInquiry.step3.leadReferralTypeID === undefined
        ) {
          return false;
        }
        return this.customerInquiry.step3.leadReferralTypeID === 2;
      },
    },
    isConsultantsOtherVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.inquiryAffiliateID === null ||
          this.customerInquiry.step3.inquiryAffiliateID === undefined ||
          this.customerInquiry.step3.leadReferralTypeID === null ||
          this.customerInquiry.step3.leadReferralTypeID === undefined
        ) {
          return false;
        }
        return (
          this.customerInquiry.step3.inquiryAffiliateID === -1 &&
          this.customerInquiry.step3.leadReferralTypeID === 2
        );
      },
    },
    isLeadReferralTypeOtherVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.leadReferralTypeID === null ||
          this.customerInquiry.step3.leadReferralTypeID === undefined
        ) {
          return false;
        }
        // This ID keeps changing so keep an eye out.  hopefully with any future DB restores or changes it will still be 28
        return this.customerInquiry.step3.leadReferralTypeID === 28;
      },
    },
    isLeadTypeOtherVisible: {
      get: function () {
        if (
          this.customerInquiry.step3.leadTypeID === null ||
          this.customerInquiry.step3.leadTypeID === undefined
        ) {
          return false;
        }
        return this.customerInquiry.step3.leadTypeID === 6;
      },
    },
  },
};
</script>
