import { appRoot } from "../constants/config";

// to corresponds to the route of the component in the router (index.js) file
const quickLinkData = [
  {
    label: "Change Request Forms",
    to: `${appRoot}/company/change-request`,
  },
  {
    label: "Upload Documents",
    to: `${appRoot}/company/upload-document`,
  },
  {
    label: "Shopping Cart",
    to: `${appRoot}/shopping-cart`,
  },
  {
    label: "News from ACHC",
    to: `${appRoot}/news/e-news-sign-up`,
  },
];

export default quickLinkData;
