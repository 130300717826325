<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      passwordRequirements: "",
      errors: "",
      status: "",
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalDangerMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "resetMessages",
    ]),
    async forgotPassword(email) {
      this.processing = true;
      await axios
        .post(`${WebAPIUrl}/api/applicationuser/send-forgot-password-reset${window.btoa(email)}`)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(result.data);
        })
        .catch((error) => {
          this.$alert("Error", error.response.data, "OK");
        });
      this.processing = false;
    },
    async resendVerificationEmail(email) {
      this.processing = true;
      await axios
        .post(`${WebAPIUrl}/api/applicationuser${window.btoa(email)}`)
        .then((result) => {
          this.statusCode = result.status;
          this.$alert("Verification Email", result.data, "OK");
        })
        .catch((error) => {
          this.$alert("Error", error.response.data, "OK");
        });
      this.processing = false;
    },
    async verifyEmail(verifyEmailData) {
      this.processing = true;
      return await axios
        .post(`${WebAPIUrl}/api/applicationuser/verify-account-email`, verifyEmailData)
        .then((result) => {
          this.resetMessages();
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setModalInfoMessage(result.data);
          }
          this.processing = false;
        })
        .catch((error) => {
          this.resetMessages();
          if (error.response) {
            // this block is for an expected 400 if the email fails to verify
            this.statusCode = error.response.status;
            if (this.statusCode === 400) {
              this.setModalDangerMessage(error.response.data);
            } else {
              this.setModalDangerMessage(this.statusCode + " error - unable to verify email.");
            }
          } else {
            // this block is for an unexpected error
            this.setModalDangerMessage("Unknown error - email verification failed.");
          }
          this.processing = false;
        });
    },
    async resetPassword(resetPasswordData) {
      this.processing = true;
      return await axios
        .post(`${WebAPIUrl}/api/applicationuser/reset-password`, resetPasswordData)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setModalInfoMessage(result.data);
          }
          this.processing = false;
        })
        .catch((error) => {
          this.resetMessages();
          if (error.response) {
            this.statusCode = error.response.status;
            if (this.statusCode === 400) {
              this.setModalDangerMessage(error.response.data);
            } else {
              this.setModalDangerMessage(this.statusCode + " error - unable to reset password.");
            }
          } else {
            this.setModalDangerMessage("Unknown error - password reset failed.");
          }
          this.processing = false;
          throw error;
        });
    },
    async getPasswordRequirements() {
      await axios
        .get(`${WebAPIUrl}/api/applicationuser/password-requirements`)
        .then((result) => {
          this.passwordRequirements =
            "Passwords must meet the following requirements:<ul><li>" +
            result.data.join("</li><li>") +
            "</li></ul>";
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async login(loginData) {
      this.processing = true;
      await axios
        .post(`${WebAPIUrl}/api/applicationuser/login`, loginData, { withCredentials: true })
        .then((result) => {
          this.statusCode = result.status;
          //'userToken' is our "bearer token"
          localStorage.setItem("userToken", result.data.token);
          //set refresh token
          localStorage.setItem("refreshToken", result.data.refreshToken);
          if (this.statusCode === 200) {
            const {
              status = "",
              token = "",
              message = "",
              email = "",
              firstname = "",
              lastname = "",
            } = result.data;
            this.status = status;
            const loggedUserData = JSON.stringify({
              email,
              firstname,
              lastname,
            });
            switch (status) {
              case "success":
                this.$socket.invoke("JoinUserGroup", result.data.token.toString());
                localStorage.setItem("userToken", token);
                localStorage.setItem("loggedUserData", loggedUserData);
                break;
              case "failure":
                this.setModalDangerMessage(message);
                break;
              case "import":
                localStorage.setItem("loggedUserData", loggedUserData);
                this.setModalInfoMessage(message);
                break;
              default:
                break;
            }
          } else {
            this.statusCode = result.status;
            this.setModalDangerMessage(result.data);
          }
        })

        .catch((error) => {
          this.statusCode = error.response.status;
          this.setModalDangerMessage(error.response.data);
        });
      this.processing = false;
    },
    //need to redo this to take in something other than the refresh token
    async removeRefreshToken(emailAddress) {
      this.processing = true;
      await axios
        .put(`${WebAPIUrl}/api/applicationuser/remove-refresh-token/${window.btoa(emailAddress)}`)
        .then((result) => {
          this.statusCode = result.status;
          ///this.setModalInfoMessage(result.data);
        })
        .catch((error) => {
          this.$alert("Error", error.response.data, "OK");
        });
      this.processing = false;
    },
  },
};
</script>
