<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      affiliates: [],
      consultants: [],
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getAllAffiliates() {
      await axios
        .get(`${WebAPIUrl}/api/affiliate/dropdown`)
        .then((result) => {
          this.affiliates = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAllConsultants() {
      await axios
        .get(`${WebAPIUrl}/api/affiliate?affiliateType=2`)
        .then((result) => {
          this.statusCode = result.status;
          this.consultants = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAffiliateTypesByAffiliateIds(affiliatesTypeIDs) {
      return await axios
        .get(`${WebAPIUrl}/api/affiliate/affiliate-by-type?affiliatesTypeIDs=${affiliatesTypeIDs}`)
        .then((result) => {
          return result.data.map((affiliate) => {
            return {
              ...affiliate,
              affiliateTypeID: affiliatesTypeIDs,
            };
          });
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
