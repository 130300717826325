<template>
  <div class="sidebarMenuContainer">
    <CompanyModal ref="CompanyModal"></CompanyModal>
    <div class="profileUtilities">
      <div class="userInitialBubble shadow" @click="goToProfile" title="View Profile">
        <h3>{{ userInitial }}</h3>
      </div>
      <div class="small-separator"></div>
      <div id="notificationBubbleContainer" v-click-outside="hideNotifications">
        <div
          @click="showHideNotificationWindow"
          class="profileUtilityBubble shadow"
          title="View Notifications"
        >
          <img src="../../assets/img/app/bell-icon-01.svg" />
        </div>
        <span class="bubbleCount" v-if="activeNotificationCount > 0">
          {{ activeNotificationCount }}
        </span>
        <b-collapse id="notificationWindow" class="notificationWindow shadow mt-1">
          <b-card class="dashboard-card notificationCard">
            <div
              v-for="notification in notifications.slice(0, 5)"
              :key="`notification-${notification.navigatorNotificationID}`"
              class="notificationRow"
            >
              <p @click="goToNotifications()">{{ notification.notificationText }}</p>
              <div
                class="activeDot"
                v-if="notification.active"
                @click="acknowledgeSidebarNotification(notification)"
                :href="notification.notificationURL"
              >
                ×
              </div>
            </div>
            <div class="notificationShowAllRow">
              <b-link
                tag="a"
                :to="{ name: 'notifications' }"
                class="notificationLink mb-3"
                style="margin: 0 auto"
              >
                Show All
              </b-link>
            </div>
          </b-card>
        </b-collapse>
      </div>
      <div
        v-if="showSwitchCompany"
        class="profileUtilityBubble shadow"
        @click="openSelectCompanyModal()"
        title="Switch Company"
      >
        <img src="../../assets/img/app/switch-comp-light-mixed-01.svg" />
        <!-- <b-button size="sm" class="switch-company" @click="openSelectCompanyModal()"
          >Switch <br />
          Company</b-button -->
      </div>
      <!-- mobileMenuCollapsibleContainer should always be the right-most option -->
      <div id="mobileMenuCollapsibleContainer">
        <div v-b-toggle.userSidebarMenu class="profileUtilityBubble shadow">
          <font-awesome-icon
            id="mobileMenuCollapsibleIcon"
            :icon="['fas', 'bars']"
            class="base-icon-light"
          />
        </div>
      </div>
      <!-- end mobileMenuCollapsibleContainer -->
    </div>
    <b-collapse id="userSidebarMenu" visible>
      <b-card class="dashboard-card shadow-sm pt-3">
        <div class="ml-20px">
          <h5 class="bold-header-text">{{ userCompanyDetails.companyLegalName }}</h5>
          <LabelWithInfoTootlip
            :label="'Company ' + userCompanyDetails.companyID"
            tooltipText="The number assigned by ACHC to your company, sometimes identified as the AOID."
          />
        </div>
        <hr />
        <div
          v-for="option in menuItems"
          :key="option.to"
          class="menuOptionRow"
          @click="goToPage(option)"
        >
          <div class="iconColumn" :class="{ selectedIconColumn: option.selected }">
            <img :src="option.selected ? option.selectedIcon : option.icon" class="menu-icon" />
          </div>
          <div class="textColumn">
            <p class="bold-gray-text">{{ option.label }}</p>
          </div>
        </div>
        <hr />
        <div class="mt-2 ml-20px quickLinksContainer">
          <div>
            <p class="bold-gray-text mb-4">Quick Links</p>
            <div
              class="quickLink"
              v-for="quickLink in quickLinks"
              :key="quickLink.linkID"
              @click="goToQuickLink(quickLink)"
            >
              <p>{{ quickLink.label }}</p>
            </div>
          </div>
          <SelectProgramModal ref="SelectProgramModal"></SelectProgramModal>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>
<style lang="scss" scoped>
#userSidebarMenu > .dashboard-card > .card-body {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  gap: 20px;
  height: 100vh;
  border-radius: 10px;
  overflow-x: hidden;
}
.notificationLink {
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.3rem;
  text-decoration: none;
  color: #28628f;
}
.quickLinksContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}
.quickLink {
  margin-left: 25px;
  cursor: pointer;
  color: #666666;
}
.quickLink:hover {
  font-weight: 600;
  color: #005175;
}
.sidebarMenuContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  gap: 20px;
}
.iconColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  height: 40px;
}
.selectedIconColumn {
  animation: selected-menu-item 100ms ease-in-out;
  background-color: #005175;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.textColumn {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
p {
  font-size: 0.8rem;
  line-height: 1rem;
}
hr {
  margin: 0 20px;
}
.notificationWindow {
  position: absolute;
  z-index: 42;
}
.notificationCard {
  width: 300px;
  max-height: 312px;
  overflow-y: auto;
}
.notificationCard {
  width: 300px;
  max-height: 312px;
  overflow-y: auto;
  // Firefox scrollbar styling
  scrollbar-width: thin;
  scrollbar-color: #d2d2d2 transparent;
}
.notificationCard > .card-body {
  padding: 0 16px;
}
// Chrome, Edge, Safari scrollbar styling
.notificationCard::-webkit-scrollbar {
  width: 4px;
}
.notificationCard::-webkit-scrollbar-track {
  background: transparent;
}
.notificationCard::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  border-radius: 3px;
}
.notificationRow {
  padding: 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.notificationRow > p {
  margin: 0;
}
.notificationRow:hover,
.notificationRow:focus {
  font-weight: 600;
  color: #005175;
}
.notificationShowAllRow {
  padding: 16px 8px 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.activeDot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  background-color: #005175;
  color: transparent;
  border-radius: 50%;
  font-size: 20px;
  line-height: 12px;
  text-align: center;
}
.activeDot:hover,
.activeDot:focus {
  color: #005175;
  background-color: transparent;
}
#notificationWindow {
  border-radius: 10px;
}
#notificationBubbleContainer {
  position: relative;
}

#mobileMenuCollapsibleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  @media (min-width: 992px) {
    display: none;
  }
}
</style>
<script>
import { mapGetters, mapMutations } from "vuex";
import router from "../../router";
import NotificationMixin from "../../mixins/NotificationsMixin.vue";
// Test Data
import quickLinkData from "../../data/quickLinkData";
import CompanyMixin from "../../mixins/CompanyMixin.vue";
import CompanyModal from "../../components/Login/CompanyModal.vue";
import SelectProgramModal from "../../components/Modals/SelectProgramModal.vue";
import jwt_decode from "jwt-decode";
import { bus } from "../../main";
import LabelWithInfoTootlip from "../../components/Common/LabelWithInfoTooltip.vue";

export default {
  components: {
    CompanyModal,
    SelectProgramModal,
    LabelWithInfoTootlip,
  },
  data() {
    return {
      quickLinks: quickLinkData,
      userCompanyDetails: {},
      userInitial: "G",
      showSwitchCompany: false,
    };
  },
  mixins: [CompanyMixin, NotificationMixin],
  async created() {
    await this.getUserNotifications();
    this.$socket.on("SendMessage", this.onNotificationsReceived);
  },

  async mounted() {
    bus.$on("newParentCompanyCreated", async () => {
      this.setUserCompanyDetails();
    });
    bus.$on("companyUpdated", async () => {
      await this.getUserNotifications();
      this.showCompanyModal();
    });
    bus.$on("notificationsUpdated", async () => {
      await this.getUserNotifications();
    });

    this.setUserCompanyDetails();
    this.showCompanyModal();
  },
  beforeDestroy() {
    bus.$off("companyUpdated");
    bus.$off("newParentCompanyCreated");
  },
  methods: {
    ...mapMutations(["changeSelectedMenuItem"]),
    async goToPage(option) {
      if (option.to === "SelectProgramModal") {
        this.$refs.SelectProgramModal.show();
      } else {
        const successfulNavigation = await router.push({ name: option.to });
        if (successfulNavigation) {
          this.changeSelectedMenuItem(option);
        }
      }
    },
    onNotificationsReceived(data) {
      const users = data.split(",");
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
      if (users.indexOf(currentCompany.aspNetUserID) > -1) {
        this.getUserNotifications();
      }
    },
    goToQuickLink(quickLink) {
      router.push(quickLink.to);
    },
    async acknowledgeSidebarNotification(notification) {
      await this.acknowledgeNotification(notification);
      await this.getUserNotifications();
    },
    hideNotifications() {
      var notificationWindow = document.getElementById("notificationWindow");
      notificationWindow.classList.remove("show");
    },
    showHideNotificationWindow() {
      var notificationWindow = document.getElementById("notificationWindow");
      if (notificationWindow.classList.contains("show")) {
        notificationWindow.classList.remove("show");
      } else {
        notificationWindow.classList.add("show");
        notificationWindow.style.display = "";
      }
    },
    async openSelectCompanyModal() {
      const { companyDetails = [] } = this.$route.params;
      var onDashboard = false;
      if (this.$route.path.includes("dashboard")) {
        onDashboard = true;
      }
      const userDetails = localStorage.getItem("userToken");
      if (userDetails) {
        const { UserID } = jwt_decode(userDetails);
        await this.getCompanyName(UserID);
      }
      this.$refs.CompanyModal.show({
        companyDetails: (companyDetails.length && companyDetails) || this.companyDetails,
        isOpenFromDashboard: onDashboard,
      });
    },
    async showCompanyModal() {
      const userDetail = localStorage.getItem("userToken");
      if (userDetail) {
        const { UserID, FirstName = "Guest" } = jwt_decode(userDetail);
        this.userInitial = FirstName[0];
        const { companyDetails = [] } = this.$route.params;
        if (companyDetails.length === 0) {
          await this.getCompanyName(UserID);
          this.showSwitchCompany = this.companyDetails.length > 1;
        } else {
          this.showSwitchCompany = companyDetails.length > 1;
        }
      }
    },
    goToProfile() {
      this.$router.push({ name: "UserProfile" });
    },
    setUserCompanyDetails() {
      const getCompanyDetails = localStorage.getItem("currentCompany");
      if (getCompanyDetails) {
        this.userCompanyDetails = JSON.parse(getCompanyDetails);
      }
    },
    goToNotifications() {
      this.$router.push({ name: "notifications" });
    },
  },
  computed: {
    ...mapGetters(["getMenuItems"]),
    collapsibleMenuClass() {
      return this.isMenuVisible ? "menuActive" : "menuInactive";
    },
    menuItems() {
      return this.getMenuItems;
    },
    activeNotificationCount() {
      return this.notifications.filter((notification) => notification.active).length;
    },
  },
};
</script>
