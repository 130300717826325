<template>
  <div class="full-page-overlay">
    <b-overlay
      class="page-overlay"
      :show="processing"
      :spinner-variant="overlayVariant"
      rounded
      variant="light"
      opacity="0.8"
    >
      <slot></slot>
      <template #overlay v-if="showWaiting">
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ waitingText }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
export default {
  props: {
    overlayVariant: {
      type: String,
      default: "primary",
    },
    showWaiting: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    waitingText: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.page-overlay .b-overlay {
  z-index: 1202 !important;
}

.page-overlay .b-overlay div:nth-child(2) {
  top: 13% !important;
}
</style>
