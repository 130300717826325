<template>
  <div>
    <b-modal
      id="alert-modal"
      ref="AlertModal"
      @hide="_exit"
      v-model="isVisible"
      :title="this.title"
    >
      <p v-html="message"></p>
      <template slot="modal-footer">
        <b-button variant="primary" class="mr-1" @click="_confirm">{{ okButton }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "alert",
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: "Okay", // Text for confirm button; leave it empty because we don't know what we're using it for

    isVisible: false,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }
      // Once we set our config, we tell the popup modal to open
      this.open();

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
    _confirm() {
      this.close();
      this.resolvePromise(true);
    },

    _exit(e) {
      if (this.exitFunction) {
        this.exitFunction(e);
      }
      this.close();
    },
  },
};
</script>
