const state = {
  company: {},
  companyProgramsOfInterests: [],
  branchLocations: [],
  companyContacts: [],
  additionalSites: [],
  companyAddressTable: [],
  companyContactTable: [],
  companyPhoneTable: [],
  changeRequestTable: [],
  applicationsList: [],
  applicationAdditionalContacts: [],
};

const getters = {
  getCompany: (state) => state.company,
  getCompanyProgramsOfInterests: (state) => state.companyProgramsOfInterests,
  getBranchLocations: (state) => state.branchLocations,
  getCompanyContacts: (state) => state.companyContacts,
  getAdditionalSites: (state) => state.additionalSites,
  getCompanyAddress: (state) => state.companyAddressTable,
  getCompanyContact: (state) => state.companyContactTable,
  getCompanyPhone: (state) => state.companyPhoneTable,
  getChangeRequest: (state) => state.changeRequestTable,
  getApplicationsList: (state) => state.applicationsList,
  getApplicationAdditionalContacts: (state) => state.applicationAdditionalContacts,
};

const mutations = {
  changeCompany(state, payload) {
    state.company = payload;
  },
  changeCompanyProgramsOfInterests(state, payload) {
    state.companyProgramsOfInterests = payload;
  },
  changeAdditionalSites(state, payload) {
    state.additionalSites = payload;
  },
  changeCompanyContacts(state, payload) {
    state.companyContacts = payload;
  },
  changeCompanyAddress(state, payload) {
    state.companyAddressTable = payload;
  },
  changeCompanyContact(state, payload) {
    state.companyContactTable = payload;
  },
  changeCompanyPhone(state, payload) {
    state.companyPhoneTable = payload;
  },
  changeRequest(state, payload) {
    state.changeRequestTable = payload;
  },
  setApplicationsData(state, payload) {
    state.applicationsList = payload;
  },
  changeApplicationAdditionalContacts(state, payload) {
    state.applicationAdditionalContacts = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
