import store from "../store";
import { bus } from "../main";
import jwt_decode from "jwt-decode";

export default async (to, from, next) => {
  const module = to.query?.module;
  if (module === "reset-password") {
    localStorage.clear();
  }
  // Check if form has dirty data and trigger warning modal if true
  const isPopupValid = from.name !== to.name;
  if (store.getters.isDirty && isPopupValid) {
    // trigger modal message to warn about dirty data
    bus.$emit("popDirtyDataModal", to, from);
    return false;
  }
  const userDetail = localStorage.getItem("userToken");
  if (to.matched.some((record) => record.meta.loginRequired)) {
    if (userDetail) {
      const { UserID } = jwt_decode(userDetail);
      if (UserID) {
        next();
        return;
      }
    }
    next({
      name: "Home",
      params: { redirect: to.fullPath },
    });
  } else {
    if (userDetail) {
      const { UserID } = jwt_decode(userDetail);
      if (UserID) {
        next({
          name: "Dashboard",
          params: { redirect: to.fullPath },
        });
      }
    } else {
      next();
    }
  }
};
