import Vue from "vue";
import Vuex from "vuex";

// template store modules
import company from "./modules/company";
import menu from "./modules/menu";
import message from "./modules/message";
import shoppingCart from "./modules/shoppingCart";
import dirtyData from "./modules/dirtyData";

import "/src/assets/fonts/simple-line-icons/css/simple-line-icons.css";
import "/src/assets/fonts/iconsmind-s/css/iconsminds.css";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    company,
    menu,
    message,
    shoppingCart,
    dirtyData,
  },
});
