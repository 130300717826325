<template>
  <header class="page-header-second maincolumn">
    <b-img
      class="navbarLogo"
      :src="require('../../assets/img/achclogonavigator.svg')"
      fluid
      alt="ACHC Logo"
      width="187"
      @click="goToDashboard"
    ></b-img>
  </header>
</template>
<style scoped>
.navbarLogo {
  cursor: pointer;
}
p {
  margin: 0;
}
</style>
<script>
import { adminRoot } from "../../constants/config";

export default {
  components: {},
  data() {
    return {
      adminRoot,
    };
  },
  methods: {
    goToDashboard() {
      const currentPath = window.location.pathname.split("/");
      if (currentPath[2] !== "dashboard") this.$router.push({ name: "App" });
    },
  },
  computed: {},
  beforeDestroy() {},
  created() {},
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
  },
};
</script>
