var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"id":"inquiryfrm"}},[_c('div',[_c('ul',{class:("nav nav-tabs " + _vm.navClass)},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('li',{key:tab.name,class:{
          'nav-item': true,
          'step-doing': tab.isActive,
          'step-done': tab.isDone,
        }},[_c('a',{class:{
            'nav-link': true,
            disabled: _vm.topNavDisabled,
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.clickedTab(tabIndex)}}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('small',[_vm._v(_vm._s(tab.desc))])])])}),0),_vm._t("default"),(!(_vm.lastStepEnd && _vm.isCompleted))?_c('div',{class:("wizard-buttons " + _vm.navClass)},[_c('button',{staticClass:"mr-1 btn btn-blue",attrs:{"type":"button","disabled":!_vm.currentActive > 0},on:{"click":function($event){return _vm.previousTab()}}},[_vm._v(" "+_vm._s("Previous")+" ")]),(_vm.currentActive < _vm.totalTabs - 1)?_c('button',{staticClass:"btn btn-blue",attrs:{"type":"button"},on:{"click":function($event){return _vm.nextTab()}}},[_vm._v(" "+_vm._s("Next")+" ")]):_vm._e(),(_vm.currentActive >= _vm.totalTabs - 1)?_c('api-button-overlay',{attrs:{"processing":_vm.wizardProcessing}},[_c('button',{staticClass:"btn btn-blue",attrs:{"type":"button"},on:{"click":_vm.done}},[_vm._v(" "+_vm._s("Finish")+" ")])]):_vm._e()],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }