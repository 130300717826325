<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      states: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getStatesGroupedByType() {
      await axios
        .get(`${WebAPIUrl}/api/state/by-type`)
        .then((result) => {
          this.states = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
