<template>
  <!-- This implementation is intended to replicate the output of b-form-group tags where the label is specified as a property -->
  <legend class="bv-no-focus-ring col-form-label pt-0">
    <span v-html="label"></span>
    &nbsp;
    <span v-if="!isHoverClickable">
      <font-awesome-icon
        :icon="['fas', 'info-circle']"
        v-b-tooltip.hover.righttop="{ variant: 'info' }"
        :title="tooltipText"
        v-if="tooltipText"
      />
    </span>
    <span
      v-b-tooltip.hover.html.right="{ customClass: 'custom-tooltip-class' }"
      :title="tooltipText"
      v-else
    >
      <font-awesome-icon :icon="['fas', 'info-circle']" v-if="tooltipText" />
    </span>
  </legend>
</template>
<script>
export default {
  props: ["label", "tooltipText", "isHoverClickable"],
};
</script>
<style scoped></style>
