<template>
  <div class="customer">
    <div class="grp-btn">
      <b-button class="btn btn-blue" v-scroll-to="{ el: '#customeriquiry', offset: -60 }"
        >New Customer?</b-button
      >
      <b-button class="btn btn-blue mt-4" v-b-modal.modal-center>Existing Customer?</b-button>
    </div>
  </div>
</template>

<script>
import { store } from "../../store/Login";
export default {
  components: {},
  computed: {
    isLoginVisible() {
      return store.isLoginOpen;
    },
    isResendVerificationEmailVisible() {
      return store.isResendVerificationEmailOpen;
    },
    isForgotPasswordVisible() {
      return store.isForgotPasswordOpen;
    },
  },
  methods: {
    openExistingCustomerModal() {
      this.$refs.ExistingCustomerModal.show();
    },
  },
};
</script>
<style scoped>
.customer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  align-self: center;
}
.grp-btn {
  display: flex;
  flex-direction: column;
  align-self: end;
  width: 100%;
  max-width: 360px;
}
</style>
