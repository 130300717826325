<template>
  <footer class="app-footer">
    <p>© 2022 ACCREDITATION COMMISSION FOR HEALTH CARE INC. ALL RIGHTS RESERVED.</p>
  </footer>
</template>
<style lang="scss" scoped>
.app-footer {
  display: flex;
  flex-direction: row;
  background-color: #e3ecf2;
  @media (min-width: 769px) {
    padding: 0 50px 30px 0;
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    padding: 0 50px 30px;
    justify-content: center;
  }
  @media (max-width: 425px) {
    padding: 20px;
  }
}
p {
  color: #737373;
  font-weight: 600;
  text-align: center;
  font-size: 0.9rem;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}
</style>
<script>
export default {
  computed: {},
};
</script>
