<template>
  <div class="logincontainer flex">
    <div id="loginheader" class="loginheader">
      <h2>{{ $t("header.forgot-password") }}</h2>
    </div>
    <messages />
    <div class="loginfrmcontainer flex">
      <div class="loginbox roundeddiv">
        <div class="loginfrm">
          <b-form class="av-tooltip tooltip-label-bottom">
            <b-form-group label="Email" class="has-float-label mb-2">
              <b-form-input
                type="email"
                v-model="$v.email.$model"
                :state="validateEmail()"
                @keydown.enter="sendForgotPasswordEmail"
              />
            </b-form-group>
            <div class="loginemail">
              <p class="logintext">
                {{ $t("customer.please-use-email-to-reset") }}
              </p>
            </div>
            <div class="loginclick" v-if="type != 'change-password'">
              <b-link tag="a" class="loginlinks" @click="goToLogin">{{
                $t("generic.back-to-login")
              }}</b-link>
            </div>
            <div class="loginbtnbg">
              <api-button-overlay :processing="processing">
                <b-button class="login-blue" @click="sendForgotPasswordEmail">
                  <span class="label">{{ $t("button.reset-password") }}</span>
                </b-button>
              </api-button-overlay>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import UserMixin from "../../mixins/UserMixin.vue";
import { mutations } from "../../store/Login";
import ModalMessages from "../../containers/navs/ModalMessages.vue";

const { required, maxLength, minLength, email } = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      email: "",
    };
  },
  props: ["type"],
  mixins: [validationMixin, UserMixin],
  components: {
    messages: ModalMessages,
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(3),
      maxLength: maxLength(50),
    },
  },
  methods: {
    async sendForgotPasswordEmail() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.forgotPassword(this.email);
      }
    },
    validateEmail() {
      const { $dirty, $error } = this.$v["email"];
      return $dirty ? !$error : null;
    },
    goToLogin() {
      mutations.toggleForgotPassword();

      mutations.toggleLogin();
    },
  },
};
</script>
