<template>
  <b-modal
    id="company-modal"
    centered
    size="lg"
    title="Select Company"
    v-model="isModalVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <b-form>
      <b-form-group>
        <v-select v-model="company" label="companyLegalName" :options="companyDetails">
          <template slot="selected-option" slot-scope="option">
            {{
              `${option.companyLegalName}  ${
                option.physicalCity ? "- " + option.physicalCity : ""
              } ${option.physicalStateInitials ? ", " + option.physicalStateInitials : ""}`
            }}
          </template>
          <template slot="option" slot-scope="option">
            {{
              `${option.companyLegalName}  ${
                option.physicalCity ? "- " + option.physicalCity : ""
              } ${option.physicalStateInitials ? ", " + option.physicalStateInitials : ""}`
            }}
          </template>
        </v-select>
      </b-form-group>
      <div class="loginbtnbg">
        <b-button class="login-blue" @click="submitCompanyDetails()">
          <span class="label">{{ "Submit" }}</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<style>
#company-modal___BV_modal_footer_ {
  display: none;
}
</style>
<script>
//import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    //multiselect: Multiselect,
    "v-select": vSelect,
  },
  data() {
    return {
      isModalVisible: false,
      company: "",
      companyDetails: [],
      redirect: undefined,
      isOpenFromDashboard: false,
    };
  },

  created() {
    const getCompanyDetails = localStorage.getItem("currentCompany");
    localStorage.setItem("currentCompany", getCompanyDetails);
    if (getCompanyDetails) {
      this.company = JSON.parse(getCompanyDetails);
    }
  },
  methods: {
    async show(modelObject) {
      const { isOpenFromDashboard, companyDetails, redirect } = modelObject;
      this.isOpenFromDashboard = isOpenFromDashboard;
      this.isModalVisible = true;
      this.companyDetails = companyDetails;
      this.redirect = redirect;
      // set the current company to the first company in this array
      // this will display the first company in the user's array of companies and prevent
      // showing companies from a previously logged in user
      this.company = companyDetails[0];
    },
    close() {
      this.isModalVisible = false;
    },
    getCompanyDetails({ companyLegalName = "", physicalCity = "", physicalStateInitials = "" }) {
      return `${companyLegalName}  ${physicalCity ? "- " + physicalCity : ""} ${
        physicalStateInitials ? ", " + physicalStateInitials : ""
      }`;
    },
    submitCompanyDetails() {
      const redirectTo =
        !this.redirect || this.redirect === "/app/dashboard"
          ? { name: "Dashboard", params: { companyDetails: this.companyDetails } }
          : this.redirect;
      var onCompanyInfoPage = false;
      if (this.$route.path.includes("details/info")) {
        onCompanyInfoPage = true;
      }
      localStorage.setItem("currentCompany", JSON.stringify(this.company));
      if (!this.isOpenFromDashboard) {
        if (onCompanyInfoPage) {
          this.$router.push({
            name: "CompanyInfo",
            params: { companyID: this.company.companyID },
          });
          this.close();
          window.location.reload();
        } else {
          this.$router.go(redirectTo);
        }
      } else {
        this.close();
        window.location.reload();
      }
    },
  },
};
</script>

<style scoped></style>
