<template>
  <header class="page-header maincolumn">
    <b-navbar>
      <b-navbar-nav class="ms-auto">
        <b-nav-item href="https://www.achc.org/find-a-provider/">FIND A PROVIDER</b-nav-item>
        <b-nav-item href="https://www.achc.org/e-news-signup/">JOIN OUR COMMUNITY</b-nav-item>
        <b-nav-item href="">DOWNLOAD STANDARDS</b-nav-item>
        <b-nav-item @click="login()" v-if="!this.userToken">LOGIN</b-nav-item>
        <b-nav-item @click="logout()" v-if="this.userToken">LOGOUT</b-nav-item>
        <b-nav-item-dropdown href="#" menu-class="flagmenu" no-caret center>
          <!-- Using 'button-content' slot -->
          <template slot="button-content">
            <div class="tz_flag_icon_us"></div>
          </template>
          <b-nav-item link-classes="flagmenu" href="https://www.achcinternational.org/ca/">
            <div class="tz_flag_icon_ca"></div>
          </b-nav-item>
          <b-nav-item link-classes="flagmenu" href="https://www.achcinternational.org/eu/">
            <div class="tz_flag_icon_eu"></div>
          </b-nav-item>
          <b-nav-item link-classes="flagmenu" href="https://www.achcinternational.org/sa/">
            <div class="tz_flag_icon_sa"></div>
          </b-nav-item>
          <b-nav-item link-classes="flagmenu" href="https://www.achcinternational.org/me/">
            <div class="tz_flag_icon_me"></div>
          </b-nav-item>
          <b-nav-item link-classes="flagmenu" href="https://www.achcinternational.org/au/">
            <div class="tz_flag_icon_aus"></div>
          </b-nav-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { adminRoot } from "../../constants/config";
import UserMixin from "../../mixins/UserMixin.vue";

export default {
  props: ["handleLoginModal"],
  mixins: [UserMixin],
  data() {
    return {
      adminRoot,
      userToken: "",
    };
  },
  methods: {
    ...mapActions(["signOut"]),

    login() {
      this.handleLoginModal();
    },

    async logout() {
      const userEmailAddressString = JSON.parse(localStorage.getItem("loggedUserData"));

      const emailAddress = userEmailAddressString?.email;
      if (emailAddress) {
        await this.removeRefreshToken(emailAddress);
      }

      localStorage.clear();
      window.location.reload();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
  },
  beforeDestroy() {},
  mounted() {
    this.userToken = localStorage.getItem("userToken");
    const { redirect } = this.$route.params;
    if (redirect && redirect !== "/") {
      this.login();
    }
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
  },
};
</script>
