<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      programs: [],
      groupedPrograms: [],
      groupedProgramsByCompanyId: [],
      programsOfInterestByApplicationCompanyId: [],
      workShopAttendedDate: null,
      workShopAttendedProgramID: null,
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getWorkshopAttendedDate(applicationID) {
      await axios
        .get(`${WebAPIUrl}/api/application/workshopattended/${applicationID}`)
        .then((result) => {
          const { workShopAttendedDate, workShopAttendedProgramID } = result.data;
          this.workShopAttendedDate =
            workShopAttendedDate && moment(workShopAttendedDate).format("yyyy-MM-DD");
          this.workShopAttendedProgramID = workShopAttendedProgramID;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateWorkshopAttendedDate(workshopDetails) {
      return await axios
        .put(`${WebAPIUrl}/api/application/workshopattended`, workshopDetails)
        .then((result) => {
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
          return false;
        });
    },
    async getAllActivePrograms() {
      await axios
        .get(`${WebAPIUrl}/api/program?active=true`)
        .then((result) => {
          this.programs = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAllProgramsByType(isInquiryForm = false) {
      await axios
        .get(`${WebAPIUrl}/api/program/programs-grouped-by-type?isInquiryForm=${isInquiryForm}`)
        .then((result) => {
          this.groupedPrograms = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAllProgramsByTypeByCompanyId(companyId) {
      await axios
        .get(`${WebAPIUrl}/api/program/programs-grouped-by-type/${companyId}`)
        .then((result) => {
          this.groupedProgramsByCompanyId = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAllProgramsByTypeV2(applicationcompanyId) {
      await axios
        .get(`${WebAPIUrl}/api/applicationprograms/${applicationcompanyId}`)
        .then((result) => {
          this.programsOfInterestByApplicationCompanyId = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
