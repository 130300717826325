<template>
  <b-modal
    id="register-modal"
    ref="register-modal"
    :title="$t('header.create-a-login')"
    size="lg"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />
    <b-form class="av-tooltip tooltip-label-bottom">
      <!-- adding name -->
      <b-row>
        <b-col>
          <b-form-group label="First Name" class="has-float-label mb-2">
            <b-form-input type="text" v-model="$v.user.firstName.$model" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Last Name" class="has-float-label mb-2">
            <b-form-input type="text" v-model="$v.user.lastName.$model" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- end -->
      <b-row>
        <b-col>
          <b-form-group label="Email" class="has-float-label mb-2">
            <b-form-input
              type="email"
              v-model="$v.user.email.$model"
              :state="validateProperty('email')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Password" class="has-float-label mb-2">
            <b-input-group class="mt-3">
              <template #append>
                <b-input-group-text @click="showPasswordToggle"
                  ><strong class="text">
                    <b-icon
                      :icon="showPassword ? 'eye-slash-fill' : 'eye-fill'"
                      aria-hidden="true"
                    ></b-icon> </strong
                ></b-input-group-text>
              </template>
              <b-form-input
                :type="showPassword ? 'text' : 'password'"
                v-model="$v.user.password.$model"
                :state="validateProperty('password')"
              />
            </b-input-group>
            <b-form-invalid-feedback
              class="d-block"
              v-if="!$v.user.password.required && $v.user.password.$dirty"
              >Please enter your password</b-form-invalid-feedback
            >
            <b-form-invalid-feedback class="d-block" v-else-if="!$v.user.password.minLength"
              >Your password must be at least 12 characters.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              class="d-block"
              v-else-if="!$v.user.password.valid && $v.user.password.$dirty"
              >Your password is missing one or many of the valid
              requirements.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Confirm Password" class="has-float-label mb-2">
            <b-input-group class="mt-3">
              <template #append>
                <b-input-group-text @click="showConfirmPasswordToggle"
                  ><strong class="text">
                    <b-icon
                      :icon="showConfirmPassword ? 'eye-slash-fill' : 'eye-fill'"
                      aria-hidden="true"
                    ></b-icon> </strong
                ></b-input-group-text>
              </template>
              <b-form-input
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="$v.user.passwordConfirm.$model"
                :state="validateProperty('passwordConfirm')"
              />
            </b-input-group>
            <b-form-invalid-feedback
              class="d-block"
              v-if="!$v.user.passwordConfirm.required && $v.user.password.$dirty"
              >Please enter your password again</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              class="d-block"
              v-else-if="!$v.user.passwordConfirm.sameAsPassword"
            >
              Your inputs do not match
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p style="margin: 10px 0px" v-html="passwordRequirements"></p>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer>
      <api-button-overlay :processing="processing">
        <b-button class="btn btn-blue" @click="formSubmit">
          {{ $t("button.register") }}
        </b-button>
      </api-button-overlay>
    </template>
  </b-modal>
</template>
<script>
import { validationMixin } from "vuelidate";
import RegisterMixin from "../../mixins/RegisterMixin.vue";
import { mapMutations } from "vuex";

import ModalMessages from "../../containers/navs/ModalMessages.vue";

const { required, minLength, email, sameAs } = require("vuelidate/lib/validators");

export default {
  props: {
    companyID: {
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      showPassword: false,
      showConfirmPassword: false,
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        cclogin: "",
      },
    };
  },
  async created() {
    await this.getPasswordRequirements();
  },
  mixins: [validationMixin, RegisterMixin],
  validations: {
    user: {
      email: {
        email,
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsUppercase && containsLowercase && containsNumber && containsSpecial;
        },
        minLength: minLength(12),
        // maxLength: maxLength(15),
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  components: {
    messages: ModalMessages,
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    show(opts = {}) {
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      if (opts.registerDetails) {
        const { email = "", firstName = "", lastName = "", cclogin = "" } = opts.registerDetails;
        this.user.email = email;
        this.user.firstName = firstName;
        this.user.lastName = lastName;
        if (cclogin) this.user.cclogin = cclogin;
      }
      this.isVisible = true;
    },
    showPasswordToggle() {
      this.showPassword = !this.showPassword;
    },
    showConfirmPasswordToggle() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    close() {
      history.replaceState(null, null, "?");

      if (this.exitFunction) {
        this.exitFunction();
      }

      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setModalDangerMessage(
          "An error occured while saving.  Please verify that your passwords meet the requirements and try again."
        );
      } else {
        await this.register(this.user, this.companyID);
        if (this.statusCode && this.statusCode === 200) {
          this.setModalInfoMessage(
            'Your login was successfully created. Before you can login to Navigator you must verify your email address by clicking the "Verify Your Account" email sent to your email address. If you do not see the email in your inbox check your spam, junk, and promotions folders.'
          );
          setTimeout(() => {
            this.close();
          }, 20000);
        }
      }
    },
    validateProperty(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>
