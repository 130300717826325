import jsonconfig from "./config.json";

export const defaultMenuType = "menu-sub-hidden"; // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = "/";
export const appRoot = "/app";
export const loginpage = "/user/loginAD";

export const WebAPIUrl = jsonconfig.WebAPIUrl;
// export const WebAPIUrl = "https://localhost:44308";

//the email to use when displaying message that need customer service
export const CustomerServiceEmail = jsonconfig.CustomerServiceEmail;

export const RouteHistory = "routehistory";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const defaultLocale = "en";
export const defaultDirection = "ltr";
export const localeOptions = [
  { id: "en", name: "English LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English RTL", direction: "rtl" },
];

export const currentUser = {};
// turn authentication on or off
export const isAuthGuardActive = true;

// auth settings for AzureAD
export const AzureclientId = "e7c9845d-dee0-4c6a-8a5c-ff1345232092";
export const Azureauthority =
  "https://login.microsoftonline.com/e0609065-1e49-4f44-8698-13ff2167530a";
