<template>
  <!-- our standard alerts that can be shown on any modal -->
  <div>
    <b-alert
      v-model="showAlertMessage"
      variant="success"
      class="rounded"
      v-if="ModalAlertMessage"
      dismissible
      ><span v-html="ModalAlertMessage"></span
    ></b-alert>
    <b-alert
      v-model="showDangerMessage"
      variant="danger"
      class="rounded"
      v-if="ModalDangerMessage"
      dismissible
      ><span v-html="ModalDangerMessage"></span
    ></b-alert>
    <b-alert
      v-model="showWarningMessage"
      variant="warning"
      class="rounded"
      v-if="ModalWarningMessage"
      dismissible
      ><span v-html="ModalWarningMessage"></span
    ></b-alert>
    <b-alert
      v-model="showInfoMessage"
      variant="info"
      class="rounded"
      v-if="ModalInfoMessage"
      dismissible
      ><span v-html="ModalInfoMessage"></span
    ></b-alert>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { bus } from "../../main";

export default {
  created() {
    bus.$on("clearmessages", () => {
      this.hideAllMessages();
    });
  },
  methods: {
    ...mapMutations([
      "setShowModalAlertMessage",
      "setShowModalDangerMessage",
      "setShowModalWarningMessage",
      "setShowModalInfoMessage",
      "hideAllMessages",
    ]),
  },
  computed: {
    ...mapGetters({
      ModalAlertMessage: "ModalAlertMessage",
      ModalDangerMessage: "ModalDangerMessage",
      ModalWarningMessage: "ModalWarningMessage",
      ModalInfoMessage: "ModalInfoMessage",
      ShowModalAlertMessage: "ShowModalAlertMessage",
      ShowModalDangerMessage: "ShowModalDangerMessage",
      ShowModalWarningMessage: "ShowModalWarningMessage",
      ShowModalInfoMessage: "ShowModalInfoMessage",
    }),
    showAlertMessage: {
      get: function () {
        return this.ShowModalAlertMessage;
      },
      set: function (v) {
        this.setShowModalAlertMessage(v);
      },
    },
    showDangerMessage: {
      get: function () {
        return this.ShowModalDangerMessage;
      },
      set: function (v) {
        this.setShowModalDangerMessage(v);
      },
    },
    showWarningMessage: {
      get: function () {
        return this.ShowModalWarningMessage;
      },
      set: function (v) {
        this.setShowModalWarningMessage(v);
      },
    },
    showInfoMessage: {
      get: function () {
        return this.ShowModalInfoMessage;
      },
      set: function (v) {
        this.setShowModalInfoMessage(v);
      },
    },
  },
};
</script>
