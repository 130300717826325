<template>
  <b-modal
    id="modal-center"
    centered
    :title="$t(title)"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="cancel()"
    @cancel="cancel()"
    @hide="cancel()"
  >
    <template>
      <login v-if="isLoginVisible" :openCompanyModal="openCompanyModal" />
      <resend-verification-email v-else-if="isResendVerificationEmailVisible" />
      <forgot-password v-else-if="isForgotPasswordVisible" />
    </template>
  </b-modal>
</template>
<style>
#modal-center___BV_modal_footer_ {
  display: none;
}
</style>
<script>
import Login from "../Login/Login.vue";
import ResendVerificationEmail from "../Login/ResendVerificationEmail.vue";
import ForgotPassword from "../Login/ForgotPassword.vue";
import { store, mutations } from "../../store/Login";
import { mapMutations } from "vuex";

export default {
  props: ["openCompanyModal"],
  components: {
    login: Login,
    "resend-verification-email": ResendVerificationEmail,
    "forgot-password": ForgotPassword,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    title() {
      if (this.isLoginVisible) return "header.login";
      else if (this.isResendVerificationEmailVisible) return "header.verifying-email";
      else if (this.isForgotPasswordVisible) return "header.forgot-password";
      return "";
    },
    isLoginVisible() {
      return store.isLoginOpen;
    },
    isResendVerificationEmailVisible() {
      return store.isResendVerificationEmailOpen;
    },
    isForgotPasswordVisible() {
      return store.isForgotPasswordOpen;
    },
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    show() {
      this.isVisible = true;
    },
    cancel() {
      history.replaceState(null, null, "?");

      this.isVisible = false;
      mutations.reset();
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
    },
  },
};
</script>
