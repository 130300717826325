<template>
  <div class="logincontainer flex">
    <div id="loginheader" class="loginheader">
      <h2>{{ $t("header.verification-email") }}</h2>
    </div>
    <div class="loginfrmcontainer flex">
      <div class="loginbox roundeddiv">
        <div class="loginfrm">
          <b-form class="av-tooltip tooltip-label-bottom">
            <b-form-group label="Email" class="has-float-label mb-2">
              <b-form-input type="email" v-model="$v.email.$model" :state="validateEmail()" />
            </b-form-group>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <b-link tag="a" class="loginlinks" @click="goToLogin">{{
                $t("generic.back-to-login")
              }}</b-link>
            </div>
            <div>
              <b-button class="btn btn-blue" @click="formSubmit">
                <span class="label">{{ $t("button.resend-verification-email") }}</span>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import UserMixin from "../../mixins/UserMixin.vue";
import { mutations } from "../../store/Login";

const { required, maxLength, minLength, email } = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      email: null,
    };
  },

  mixins: [validationMixin, UserMixin],
  validations: {
    email: {
      required,
      email,
      minLength: minLength(3),
      maxLength: maxLength(50),
    },
  },
  methods: {
    async formSubmit() {
      this.$v.email.$touch();
      if (!this.$v.email.$anyError) {
        await this.resendEmail();
      }
    },
    async resendEmail() {
      await this.resendVerificationEmail(this.email);
      if (this.statusCode === 200) {
        this.$notify("success", "Resend Verification Email Success", "Please check your email.", {
          duration: 3000,
          permanent: false,
        });
      }
    },
    validateEmail() {
      const { $dirty, $error } = this.$v["email"];
      return $dirty ? !$error : null;
    },
    goToLogin() {
      mutations.toggleResendVerificationEmail();

      mutations.toggleLogin();
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    isDisabled() {
      if (this.statusCode) {
        return true;
      }
    },
    isProcessing() {
      return this.processing && !this.statusCode;
    },
    isSuccess() {
      return !this.processing && this.statusCode === 200;
    },
    isFailure() {
      return !this.processing && this.statusCode === 400;
    },
  },
};
</script>
