<template>
  <full-page-overlay :processing="processing" :showWaiting="true" :waitingText="waitingText">
    <div class="wrapper">
      <topnav :handleLoginModal="handleLoginModal" />
      <secondtopnav />
      <RegisterModal ref="RegisterModal" :companyID="companyID"></RegisterModal>
      <ResetPasswordModal ref="ResetPasswordModal"></ResetPasswordModal>
      <VerifyEmailModal ref="VerifyEmailModal"></VerifyEmailModal>
      <ExistingCustomerModal
        ref="ExistingCustomerModal"
        :openCompanyModal="openCompanyModal"
      ></ExistingCustomerModal>
      <CompanyModal ref="CompanyModal"></CompanyModal>
      <main class="maincontent">
        <section class="mainbg">
          <div class="maincolumn flex-col">
            <div class="main-message">
              <h1 class="navigatortext">Guiding your experience with ACHC!</h1>
              <h2>
                Our mission is to deliver the best possible experience and to partner with
                organizations and healthcare professionals that seek accreditation and related
                services.
              </h2>
              <customerbuttons />
            </div>
          </div>
        </section>
        <section class="achclogobg">
          <div class="flex center">
            <div class="logomessagewrapper">
              <div class="translogoimg">
                <b-img
                  :src="require('../assets/img/logo-w.png')"
                  alt="ACHC Logo"
                  width="150em"
                ></b-img>
              </div>
              <div class="corpmessage">
                <h2>
                  Our mission is to deliver the best possible experience and to partner with
                  organizations and healthcare professionals that seek accreditation and related
                  services.
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="createaccountwrapper">
          <div class="maincolumn flex">
            <div class="createaccountmessage flex-col flex-center">
              <h2>Let's get acquainted!</h2>
              Healthcare is people helping people. Three easy steps will help us get you to the
              resources you need.
              <span class="mt-3"
                >After Step 3, you'll have the option to create a login and password, giving you
                direct access to the previews of the standards and more. Not ready? No problem, our
                team will respond prompty to answer all your questions.
              </span>
            </div>
            <div id="customeriquiry" class="customeriquiry">
              <inquiry-form @register="goToRegister"></inquiry-form>
            </div>
          </div>
        </section>
        <section class="customertestimonial">
          <div class="maincolumn flex">
            <div class="testimonialwrapper">
              <div class="testimonialquoteimg">
                <b-img
                  :src="require('../assets/img/quote.png')"
                  fluid
                  alt="CustomerQuote"
                  width="150"
                ></b-img>
              </div>
              <div class="testimonial">
                I have obtained successful accreditation by [another accreditor] for 30 years, and
                this was my first experience with ACHC. By far, this was the very best survey
                experience I have had in my entire career. The process of enrollment was smooth, and
                the staff were very knowledgeable and helpful. The example policies were very
                useful. The standards are relevant and easy to understand and apply to our business,
                and the surveyor we had was phenomenal. Well done, ACHC!
                <div class="testimonialcompany">— Augusta Health, Inc.</div>
              </div>
            </div>
          </div>
        </section>
        <section class="compass" style="display: none">
          <div class="maincolumn flex">
            <div id="compasswrapper" class="compasswrapper flex">
              <div class="compassimg flex">
                <b-img
                  :src="require('../assets/img/compass.png')"
                  fluid
                  alt="compass"
                  width="150"
                ></b-img>
                <div class="compassimgtxt">*until end of 2022</div>
              </div>
              <div class="compassmaintxtwrapper flex">
                <div class="compassmaintxt">
                  Corp Comm - For hospital (including CAH), ASC, Office-based Surgery, and
                  Laboratory Accreditation and for Joint Replacement, Stroke, Wound Care, and
                  Lithotripsy Certification, login to Compass using the link below.
                </div>
              </div>
              <div class="compassbtn">
                <button
                  onclick="location.href='https://hfap-client.compwalk.com/login'"
                  type="button"
                  class="btn btn-yellow"
                >
                  Compass
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer-component />
    </div>
  </full-page-overlay>
</template>

<script>
import Topnav from "../containers/navs/Topnav";
import SecondTopnav from "../containers/navs/SecondTopnav";
//import Login from "../components/Login/Login.vue";
import CustomerButtons from "../components/Login/CustomerButtons.vue";
import RegisterModal from "../components/Login/RegisterModal.vue";
import ResetPasswordModal from "../components/Login/ResetPasswordModal.vue";
import VerifyEmailModal from "../components/Login/VerifyEmailModal.vue";

import Footer from "../containers/navs/Footer";
import InquiryForm from "../containers/wizard/InquiryForm";
import { mapGetters } from "vuex";
import { store, mutations } from "../store/Login";
import ExistingCustomerModal from "../components/Login/ExistingCustomerModal.vue";
import jwt_decode from "jwt-decode";
import CompanyMixin from "../mixins/CompanyMixin.vue";
import CompanyModal from "../components/Login/CompanyModal.vue";
import { bus } from "../main";

export default {
  components: {
    topnav: Topnav,
    secondtopnav: SecondTopnav,
    customerbuttons: CustomerButtons,
    //login: Login,

    RegisterModal: RegisterModal,
    ResetPasswordModal: ResetPasswordModal,
    VerifyEmailModal: VerifyEmailModal,

    "inquiry-form": InquiryForm,
    "footer-component": Footer,
    ExistingCustomerModal,
    CompanyModal,
  },
  mixins: [CompanyMixin],
  data() {
    return {
      show: false,
      companyID: null,
      processing: false,
      waitingText: "",
    };
  },
  computed: {
    ...mapGetters(["getMenuType"]),
    isLoginVisible() {
      return store.isLoginOpen;
    },
    isResendVerificationEmailVisible() {
      return store.isResendVerificationEmailOpen;
    },
    isForgotPasswordVisible() {
      return store.isForgotPasswordOpen;
    },
  },
  mounted() {
    document.body.classList.add("default-transition");

    // Check URL for any params
    const urlParams = new URL(location.href).searchParams;
    var param = urlParams.get("module");
    if (param) {
      mutations.reset();

      if (param === "forgot-password") {
        mutations.toggleForgotPassword();
      } else if (param === "resend-verification-email") {
        mutations.toggleLogin();
        mutations.toggleResendVerificationEmail();
        this.$refs.ExistingCustomerModal.show({
          exitFunction: () => {
            window.location.reload();
          },
        });
      } else if (param === "registration") {
        // Check if this is from a user request email link
        // User request emails always include the companyID
        var companyID = urlParams.get("companyID");
        if (companyID) {
          // URL encoding changes +'s to spaces
          // This line replaces those spaces with the +'s that were removed in encoding
          this.companyID = companyID.replaceAll(" ", "+");
        }

        this.$refs.RegisterModal.show({
          exitFunction: () => {
            window.location.reload();
          },
        });
      } else if (param === "reset-password") {
        this.$refs.ResetPasswordModal.show({
          email: urlParams.get("email"),
          token: urlParams.get("token"),
          exitFunction: () => {
            this.$refs.ExistingCustomerModal.show();
          },
        });
      } else if (param === "verify-email") {
        this.$refs.VerifyEmailModal.show({
          email: urlParams.get("email"),
          token: urlParams.get("token"),
          // exitFunction: () => {
          //   window.location.reload();
          // },
        });
      }
    }
    bus.$on("showFullpageOverlayLayout", (layoverObj) => {
      this.processing = layoverObj.showLayover;
      this.waitingText = layoverObj.message;
    });
  },
  beforeDestroy() {
    bus.$off("popDirtyDataModal");
    bus.$off("showFullpageOverlayLayout");
  },
  created() {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams) {
        const userData = urlParams.get("userData");
        if (userData) {
          const {
            email = "",
            firstname = "",
            lastname = "",
            refreshToken = "",
            token = "",
          } = JSON.parse(urlParams.get("userData") || "{}");
          //'userToken' is our "bearer token"
          localStorage.setItem("userToken", token);
          //set refresh token
          localStorage.setItem("refreshToken", refreshToken);
          const loggedUserData = JSON.stringify({
            email,
            firstname,
            lastname,
          });
          localStorage.setItem("loggedUserData", loggedUserData);
          this.openCompanyModal(true);
          //this.$socket.invoke("JoinUserGroup", token.toString());
        }
      }
    }
  },
  methods: {
    goToRegister(registerDetails) {
      this.$refs.RegisterModal.show({
        registerDetails: registerDetails,
        exitFunction: () => {
          window.location.reload();
        },
      });
    },
    async openCompanyModal(fromHorizonLogin = false) {
      var onDashboard = false;
      if (this.$route.path.includes("dashboard")) {
        onDashboard = true;
      }
      const userDetails = localStorage.getItem("userToken");
      if (userDetails) {
        if (!fromHorizonLogin) {
          this.$refs.ExistingCustomerModal.cancel();
        }
        const { UserID } = jwt_decode(userDetails);
        await this.getCompanyName(UserID);
        const { redirect } = this.$route.params;
        const redirectTo =
          !redirect || redirect === "/app/dashboard"
            ? { name: "Dashboard", params: { companyDetails: this.companyDetails } }
            : redirect;
        if (this.companyDetails && this.companyDetails.length > 1) {
          this.$refs.CompanyModal.show({ companyDetails: this.companyDetails, redirect });
          //this user has more than one parent company
          localStorage.setItem("currentCompany", JSON.stringify(this.companyDetails[0]));

          this.$refs.CompanyModal.show({
            companyDetails: this.companyDetails,
            isOpenFromDashboard: onDashboard,
          });
        } else if (this.companyDetails.length === 1) {
          //this user only has one parent company
          localStorage.setItem("currentCompany", JSON.stringify(this.companyDetails[0]));
          this.$router.go(redirectTo);
        }
      }
    },
    async handleLoginModal() {
      this.$refs.ExistingCustomerModal.show();
    },
  },
};
</script>
