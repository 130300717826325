<script>
import store from "../store";

export default {
  data() {
    return {};
  },

  methods: {
    async setDirtyData(isDirty) {
      store.commit("setDirtyData", isDirty);
    },

    initDirtyDataWatcher(modelName) {
      // Dirty Data Watcher to pop warning modal about usnaved changes
      this.$watch(modelName, {
        handler() {
          this.setDirtyData(true);
        },
        deep: true,
      });

      // This handles refresh button and page exit on dirty data
      window.onbeforeunload = function () {
        if (store.getters.isDirty) {
          return "This string does nothing, and will not show in the message";
        }
      };
    },
  },
};
</script>
