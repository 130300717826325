import axios from "axios";

const instance = axios.create({
  transformRequest: [
    (data) => {
      data = {
        ...data,
        createdByID: null,
        dateCreated: null,
        createdByAspNetUserID: null,
      };
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

instance.interceptors.request.use(
  (request) => {
    var token = localStorage.getItem("userToken");
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { instance };
