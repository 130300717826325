<template>
  <form id="inquiryfrm">
    <div>
      <ul :class="`nav nav-tabs ${navClass}`">
        <li
          :class="{
            'nav-item': true,
            'step-doing': tab.isActive,
            'step-done': tab.isDone,
          }"
          v-for="(tab, tabIndex) in tabs"
          v-bind:key="tab.name"
        >
          <a
            :class="{
              'nav-link': true,
              disabled: topNavDisabled,
            }"
            href="#"
            @click.prevent="clickedTab(tabIndex)"
          >
            <span>{{ tab.name }}</span>
            <small>{{ tab.desc }}</small>
          </a>
        </li>
      </ul>
      <slot></slot>
      <div :class="`wizard-buttons ${navClass}`" v-if="!(lastStepEnd && isCompleted)">
        <button
          type="button"
          class="mr-1 btn btn-blue"
          :disabled="!currentActive > 0"
          @click="previousTab()"
        >
          {{ "Previous" }}
        </button>
        <button
          type="button"
          class="btn btn-blue"
          v-if="currentActive < totalTabs - 1"
          @click="nextTab()"
        >
          {{ "Next" }}
        </button>
        <api-button-overlay :processing="wizardProcessing" v-if="currentActive >= totalTabs - 1">
          <button type="button" class="btn btn-blue" @click="done">
            {{ "Finish" }}
          </button>
        </api-button-overlay>
      </div>
    </div>
  </form>
</template>

<script>
import { bus } from "../../main";

export default {
  name: "form-wizard",
  props: {
    navClass: {
      default: "justify-content-center",
    },
    lastStepEnd: {
      default: false,
    },
    topNavDisabled: {
      default: false,
    },
    withValidate: {
      default: false,
    },
    done: {
      type: Function,
      default: () => {
        // console.log("called done!");
      },
    },
  },
  data() {
    return {
      tabs: [],
      currentActive: 0,
      totalTabs: 0,
      isCompleted: false,
      wizardProcessing: false,
      childrenList: [],
    };
  },

  created() {
    //only count tab components as children
    this.childrenList = this.$children;

    // listen for the wizardProcessingEvent fired from other components
    bus.$on("wizardProcessing", (data) => {
      this.wizardProcessing = data;
    });
  },
  mounted() {
    this.tabs = this.childrenList.filter((x) => x.type === "tab");
    this.totalTabs = this.tabs.length;
  },
  methods: {
    tabStatusFix() {
      this.tabs.forEach((tab, tabIndex) => {
        let isDone = tab.isDone;
        if (!isDone) {
          isDone = this.currentActive > tabIndex;
        }
        tab.isDone = isDone;
        tab.isActive = false;
      });
    },
    clickedTab(tabIndex) {
      if (!this.topNavDisabled) {
        let valid = true;
        if (this.withValidate) {
          valid = this.tabs[this.currentActive].validate();
          if (!valid) return;
        }
        if (!(this.lastStepEnd && this.isCompleted)) {
          this.currentActive = tabIndex;
          this.tabStatusFix();
          this.tabs[this.currentActive].isActive = true;
        }
      }
    },
    previousTab() {
      this.currentActive--;
      this.tabStatusFix();
      this.tabs[this.currentActive].isActive = true;
    },

    nextTab() {
      let valid = true;
      if (this.withValidate) {
        valid = this.tabs[this.currentActive].validate();
        if (!valid) return;
      }

      if (valid) {
        this.currentActive++;
        this.tabStatusFix();
        if (this.currentActive >= this.totalTabs) {
          this.isCompleted = true;
          const doneTab = this.tabs.find((x) => x.type == "done");
          if (doneTab) {
            doneTab.isActive = true;
          } else this.tabs[this.currentActive - 1].isActive = true;
        } else this.tabs[this.currentActive].isActive = true;
      }
    },
  },
  updateWizardProcessing(processing) {
    this.wizardProcessing = processing;
  },
};
</script>
