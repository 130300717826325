import VueAlert from "./Alert.vue";

import BootstrapVue from "bootstrap-vue";

export default {
  install(Vue) {
    const Constr = Vue.extend(VueAlert);
    Vue.use(BootstrapVue);
    const alert = new Constr();
    const vm = alert.$mount();
    document.querySelector("body").appendChild(vm.$el);
    Vue.$alert = Vue.prototype.$alert = (title, message, buttonText) => {
      alert.show({
        title: title,
        message: message,
        okButton: buttonText,
      });
    };
  },
};
