<template>
  <div>
    <b-modal
      id="modalbasic"
      size="lg"
      ref="modalbasic"
      title="E-news Signup"
      body-bg-variant="white"
      header-class="e-signup-header"
      @hide="close()"
      v-model="isVisible"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      @cancel="close()"
    >
      <h5 class="e-signup-title">Important Industry Updates & Helpful Info</h5>
      <form id="LoginForm"></form>
    </b-modal>
    <div class="footerimg"></div>
    <div class="footer-blocks-container">
      <div class="community-footer-block">
        <h3>Join our Community</h3>
        <p>Get ACHC updates - straight to your inbox.</p>
        <div style="margin-top: 10px">
          <input
            type="email"
            v-model="emailAddress"
            class="community-footer-input-field"
            placeholder="Enter your email address"
          />
          <input class="community-footer-input-button" @click="submitEmail(emailAddress)" />
        </div>
      </div>
      <div class="address-footer-block">
        <div class="address-footer-content">
          <img src="../../assets/img/footer/achc-logo-w.png" />
          <b>Accreditation Commission for Health Care, Inc.</b>
          <p>139 Weston Oaks Ct. | Cary, NC 27513</p>
          <a href="mailto:customerservice@achc.org" class="mailto-link" target="_blank"
            >customerservice@achc.org</a
          >
          <p>Toll-free: (855) 937-2242 | Fax: (919) 785-3011</p>
          <div class="footer-social">
            <div>
              <a href="https://www.facebook.com/ACHCINC" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/accreditation-commission-for-health-care/"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'linkedin']" />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/user/ACHCorg" target="_blank">
                <font-awesome-icon :icon="['fab', 'youtube']" />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/achc" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
            </div>
            <a @click="submenuVisible = !submenuVisible" class="menu-button"
              >Menu <i :class="submenuVisible ? 'chevron-up' : 'chevron-down'"></i
            ></a>
          </div>
          <transition name="slide">
            <ul class="footer-submenu" v-show="submenuVisible">
              <li>
                <a href="https://www.achc.org/contact/" target="_blank">Contact Us</a>
              </li>
              <li>
                <a href="https://www.achc.org/spotlight/" target="_blank">Spotlight</a>
              </li>
              <li>
                <a href="https://www.achc.org/find-a-provider/" target="_blank">Find A Provider</a>
              </li>
              <li>
                <a href="https://www.achc.org/careers/" target="_blank">ACHC Careers</a>
              </li>
              <li>
                <a href="https://www.achc.org/achcu-education/" target="_blank">ACHCU Education</a>
              </li>
              <li>
                <a href="https://bc.achc.org/" target="_blank">Board Central</a>
              </li>
              <li>
                <a href="http://bc.achcinternational.org/SitePages/DevHome.aspx" target="_blank"
                  >Board Central International</a
                >
              </li>
              <li>
                <a href="http://10.25.12.17/AMSApp/" target="_blank">Employee Central</a>
              </li>
              <li>
                <a href="http://sc.achc.org/login.asp" target="_blank">Surveyor Central</a>
              </li>
              <li>
                <a href="https://www.achc.org/iso-accreditation/" target="_blank"
                  >ISO Accreditation</a
                >
              </li>
              <li>
                <a href="https://www.achc.org/terms-and-conditions/" target="_blank"
                  >Terms and Conditions</a
                >
              </li>
            </ul>
          </transition>
        </div>
      </div>
    </div>
    <div class="copyright-footer col-12">
      © 2021 ACCREDITATION COMMISSION FOR HEALTH CARE INC. ALL RIGHTS RESERVED.
    </div>
  </div>
</template>
<style lang="scss">
.e-signup-header {
  background-color: #28628f;
  .modal-title {
    width: 100%;
    text-align: center;
  }
  .close,
  .modal-title {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
.e-signup-title {
  text-align: center;
  font-size: 15px;
  color: #28628f;
  font-weight: bold;
  padding: 15px 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.33s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
.address-footer-content {
  margin-left: 48%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  b {
    color: white;
    font-size: 16px;
  }
  p {
    color: white;
    font-size: 15px;
    margin: 3px 0px;
  }
  img {
    width: 150px;
    margin-bottom: 50px;
  }
  .mailto-link {
    color: white;
    font-size: 16px;
    text-decoration: none;
  }
  .mailto-link:hover {
    color: #e2e318;
  }
  .menu-button {
    cursor: pointer;
    color: white !important;
    text-transform: uppercase;
    font-size: 13px;
    border: 1px solid white;
    border-radius: 30px;
    padding: 9px 30px;
    text-align: center;
    text-decoration: none;
  }
  // Mobile devices
  @media only screen and (max-width: 360px) {
    .menu-button {
      display: block;
      width: 180px;
      margin-top: 15px;
    }
  }
  .footer-social {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }
  .footer-social div a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    height: 39px;
    width: 39px;
    border-radius: 30px;
    color: white;
    text-decoration: none;
  }
  .footer-social div a:hover {
    color: #e2e318;
    border: 1px solid #e2e318;
  }
  .footer-social div a i {
    font-size: 1rem;
  }
  .footer-submenu {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    display: inline-block;
    background-color: transparent;
    width: 450px;
    list-style-type: circle;
  }
  // Mobile devices
  @media only screen and (max-width: 767px) {
    .footer-submenu {
      width: 100%;
      padding: 10px;
    }
  }
  .footer-submenu li {
    width: calc(100% / 2 - 10px);
    height: auto;
    line-height: 16px;
    text-align: left;
    display: inline-block;
    border: none;
    list-style: none;
    padding-top: 7px;
    margin-right: 10px;
  }
  // Mobile devices
  @media only screen and (max-width: 767px) {
    .footer-submenu li {
      width: calc(100% / 1 - 10px);
    }
  }
  .footer-submenu li a {
    font-size: 13px;
    color: white;
    text-decoration: none;
  }
  .footer-submenu li a:hover {
    color: #e2e318;
  }
}
// Mobile devices
@media only screen and (max-width: 767px) {
  .address-footer-content {
    margin-left: 0%;
    margin: 0 auto;
    padding: 20px;
    img {
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
}
// Base styles for most devices sizes
.address-footer-block {
  position: absolute;
  background-image: url("../../assets/img/footer/achc-footer-triangle.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  width: 100%;
}
.footerimg {
  background-repeat: no-repeat;
  background-image: url("../../assets/img/Contact-ACHC-FooterImg-1.jpg");
  background-size: cover;
  height: 650px;
  margin-bottom: -320px;
}
// 4K screen sizes
@media only screen and (min-width: 2140px) {
  .address-footer-block {
    background-image: url("../../assets/img/footer/achc-footer-triangle-4kscreen.png");
    height: 610px;
  }
}
// Tablets thru large laptops
@media only screen and (max-width: 2139px) and (min-width: 768px) {
  .address-footer-block {
    height: 610px;
  }
}
// Mobile devices
@media only screen and (max-width: 767px) {
  .address-footer-block {
    background-position: center;
    width: 100%;
    position: relative;
    bottom: -250px;
    padding-top: 20px;
  }
}
// Base styles for most devices sizes
.community-footer-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 11;
  width: 45%;
  height: 610px;
  padding: 0 85px;
  padding-top: 100px;
  h3 {
    margin: 0;
    padding: 0;
    line-height: 22px;
    color: white;
    font-weight: 400;
    font-size: 1.17rem;
  }
  p {
    margin: 13px 0;
    color: white;
    font-size: 14px;
  }
}
// 4K screen sizes
@media only screen and (min-width: 2140px) {
  .community-footer-block {
    background-image: url("../../assets/img/footer/achc-footer-slant-4kscreen.png");
    background-repeat: no-repeat;
  }
}
// Large laptops
@media only screen and (max-width: 2139px) {
  .community-footer-block {
    background-image: url("../../assets/img/footer/achc-footer-slant.png");
    background-repeat: no-repeat;
  }
}
// Tablets thru small laptops
@media only screen and (max-width: 1024px) {
  .community-footer-block {
    background-image: url("../../assets/img/footer/achc-footer-slant.png");
    background-repeat: no-repeat;
    padding: 0 15px;
  }
}
// Mobile devices
@media only screen and (max-width: 767px) {
  .community-footer-block {
    background-color: #74a2bf;
    padding-bottom: 120px;
    padding-top: 50px;
    z-index: 9;
    width: 100%;
    height: 350px;
  }
}
.community-footer-input-field {
  width: 80%;
  border-radius: 30px;
  background-color: #005175;
  border: 1px solid #005175;
  color: white;
  font-size: 15px;
  padding: 10px 15px;
  margin-right: 5px;
}
// 4K screen sizes
@media only screen and (min-width: 2140px) {
  .community-footer-input-field {
    width: 30%;
  }
}
.community-footer-input-button {
  background-image: url("../../assets/img/footer/right-arrow-w.png");
  padding: 5px 15px;
  background-size: cover;
  background-position: center;
  width: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.copyright-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 12px;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;
  color: #fff;
  background-color: #6188a6;
  margin-top: 610px;
  z-index: 12;
}
// Mobile devices
@media only screen and (max-width: 767px) {
  .copyright-footer {
    margin-top: 250px;
  }
}
</style>
<script>
import { validationMixin } from "vuelidate";
const { required, email, minLength } = require("vuelidate/lib/validators");

const $ = require("jquery");
window.jQuery = $;

export default {
  data() {
    return {
      submenuVisible: false,
      isVisible: false,
      emailAddress: "",
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  mixins: [validationMixin],
  validations: {
    emailAddress: {
      required,
      email,
      minLength: minLength(4),
    },
  },
  methods: {
    submitEmail(emailAddress) {
      if (emailAddress) {
        if (this.reg.test(emailAddress)) {
          const script = document.createElement("script");
          script.src = "//js.hsforms.net/forms/v2.js";
          document.body.appendChild(script);
          script.addEventListener("load", () => {
            if (window.hbspt) {
              window.hbspt.forms.create({
                region: "na1",
                portalId: "5234004",
                formId: "a5ac39d8-5e13-4ebe-aa88-57c1e92509be",
                target: "#LoginForm",
                onFormReady: ($form) => {
                  $form.find('input[name="email"]').val(emailAddress).change();
                },
              });
            }
          });
          this.isVisible = true;
        } else {
          alert("Please Entre A Valid Email Address");
        }
      } else {
        alert("Please Enter Email Address");
      }
    },
    close() {
      this.isVisible = false;
      this.emailAddress = "";
    },
  },
};
</script>
