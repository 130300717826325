import data from "../../data/menu";
import store from "../../store";

const state = {
  menuItems: data,
};

const getters = {
  getMenuItems: (state) => state.menuItems,
};

const mutations = {
  changeSelectedMenuItem(state, payload) {
    if (!store.getters.isDirty) {
      state.menuItems.forEach((element) => {
        element.selected = element.to === payload.to ? true : false;
      });
    }
  },
  changeSelectedMenuItemByRoute(state, route) {
    state.menuItems.forEach((element) => {
      element.selected = element.to === route ? true : false;
    });
  },
};

export default {
  state,
  getters,
  mutations,
};
