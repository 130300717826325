<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      accreditationOrganizations: [],
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getAllActiveAccreditationOrganizations() {
      await axios
        .get(`${WebAPIUrl}/api/accreditation-organizations`)
        .then((result) => {
          this.accreditationOrganizations = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
