import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// Multi Language Add
import en from "./locales/en.json";
import es from "./locales/es.json";
import VueI18n from "vue-i18n";
import _ from "lodash";
import { getCurrentLanguage } from "./utils";
import { appRoot } from "./constants/config";
// bootstrap imports
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
//import { VueScrollTo } from "vue-scrollto";
var VueScrollTo = require("vue-scrollto");
//font-awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTwitter, faYoutube, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import ApiButtonOverlay from "./components/Common/ApiButtonOverlay";
import FullPageOverlay from "./components/Common/FullPageOverlay";
import alert from "./components/Common/alert/alert";
import VueMask from "v-mask";
import VueSignalR from "@latelier/vue-signalr";
import { WebAPIUrl } from "./constants/config";
import "@progress/kendo-ui";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { ContextMenu } from "@progress/kendo-layout-vue-wrapper";

Vue.use(ContextMenu);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
const messages = { en: en, es: es };
const locale = getCurrentLanguage();
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages,
});

Vue.use(alert);
Vue.use(VueSignalR, `${WebAPIUrl}/notification-hub`);
Vue.use(VueScrollTo);
Vue.use(VueMask);
Vue.use(alert);

// Globablly define appRoot variable as this.$appRoot.
// Unfortunately not accessible from js files unless the $appRoot reference is in a Vue instance
// so this might not be super useful but whatev.
// An example use case for this exists in the AdditionalSite.vue component.
Vue.prototype.$appRoot = appRoot;

//brand icons
library.add(faTwitter, faYoutube, faLinkedin, faFacebook);
//solid svg icons
library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("api-button-overlay", ApiButtonOverlay);
Vue.component("full-page-overlay", FullPageOverlay);

// use lodash globally
Object.defineProperty(Vue.prototype, "_", { value: _ });

axios.interceptors.request.use(
  (request) => {
    var token = localStorage.getItem("userToken");
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalConfig = error.config;
    if (originalConfig.url !== "/login" && error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        //set retry so we dont loop infinitely while trying to refresh auth tokens
        originalConfig._retry = true;
        try {
          //post back our bearer token and refresh token to our refresh tokens call
          const rs = await axios.post(`${WebAPIUrl}/api/applicationuser/refresh-tokens`, {
            RefreshToken: localStorage.getItem("refreshToken"),
            token: localStorage.getItem("userToken"),
          });
          const { token, refreshToken } = rs.data;
          //set the new tokens in localStorage
          localStorage.setItem("userToken", token);
          localStorage.setItem("refreshToken", refreshToken);
          //could set the bearer token in the headers (below) but that is being done on each request (above).
          // error.config.headers["Authorization"] = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(originalConfig)
              .then((response) => {
                resolve(response);
              })
              .catch((err) => {
                reject(err);
              });
          });
        } catch (_error) {
          if (_error.response.status === 400) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("loggedUserData");
            localStorage.removeItem("currentCompany");
            localStorage.removeItem("refreshToken");
            window.location.reload();
            return Promise.reject(_error);
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.checkOutside = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener("click", el.checkOutside);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.checkOutside);
  },
});

Vue.config.productionTip = false;
export const bus = new Vue();
new Vue({
  i18n,
  router,
  store,

  created() {
    this.$socket.start({
      log: false, // Active only in development for debugging.
    });

    // Signal R
    var userToken = localStorage.getItem("userToken");
    if (userToken !== null && userToken !== "") {
      this.$socket.invoke("JoinUserGroup", userToken.toString());
    }
  },
  beforeDestroy() {
    var userToken = localStorage.getItem("userToken");
    if (userToken !== null && userToken !== "") {
      this.$socket.invoke("LeaveUserGroup", userToken);
    }

    this.$socket.stop();
  },
  sockets: {
    RecentNotifications() {},
    JoinUserGroup() {},
    SendMessage() {},
    LeaveUserGroup() {},
    JoinDepartmentGroup() {},
    LeaveDepartmentGroup() {},
  },
  render: (h) => h(App),
}).$mount("#app");
