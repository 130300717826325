<template>
  <b-modal
    id="program-modal"
    ref="program-modal"
    title="Program Selector"
    size="lg"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />
    <p>
      Choosing the programs relevant to your organization customizes your view throughout Navigator
      and auto-populates content on your application. Need to make a change? You can update programs
      on each page. Looking for something we don’t currently offer? Add it under Other Programs for
      future consideration.
    </p>
    <b-row>
      <b-col>
        <b-form-group label="Selected Program(s)">
          <multiselect
            v-model="selectedProgramsByCompany"
            :options="groupedPrograms"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select Programs..."
            track-by="programID"
            group-values="options"
            group-label="label"
            label="programDescription"
          >
            <template slot="option" slot-scope="props">
              <span
                v-if="props.option.$isLabel"
                class="option__title"
                :title="props.option.$groupLabel"
                >{{ props.option.$groupLabel }}</span
              >
              <span v-else class="option__title" :title="props.option.programDetailDescription">{{
                props.option.programDescription
              }}</span>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-form-group label="Other Programs" class="has-float-label mb-2">
          <p><strong>Note:</strong> Please separate each program by a comma</p>
          <b-form-input type="text" v-model="companyObject.otherSelectedPrograms" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3">
        <div class="loginbtnbg">
          <api-button-overlay :processing="processing">
            <b-button class="login-blue" @click="submitPrograms">
              <span class="label">Submit</span>
            </b-button>
          </api-button-overlay>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>
<script>
import UserMixin from "../../mixins/UserMixin.vue";
import CompanyMixin from "../../mixins/CompanyMixin.vue";
import ProgramMixin from "../../mixins/ProgramMixin.vue";

import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import ModalMessages from "../../containers/navs/ModalMessages.vue";
//import { mutations } from "../../store/Login";

export default {
  data() {
    return {
      isVisible: false,
      company: "",
      otherPrograms: "",
      companyID: 0,
    };
  },
  mixins: [UserMixin, CompanyMixin, ProgramMixin],
  components: {
    multiselect: Multiselect,
    messages: ModalMessages,
  },
  async created() {
    this.company = {
      otherSelectedPrograms: "",
    };
    var { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
    await this.getSelectedProgramsByCompany(companyID);
    await this.getAllProgramsByType();
    await this.getCompanyObjectByID(companyID);
    this.companyID = companyID;
  },

  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    async show() {
      await this.getSelectedProgramsByCompany(this.companyID);
      await this.getCompanyObjectByID(this.companyID);
      this.isVisible = true;
    },
    close() {
      this.clearData();
    },
    clearData() {
      this.company = "";
      this.otherPrograms = "";
    },
    async submitPrograms() {
      var { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
      await this.updateSelectedPrograms(
        companyID,
        this.selectedProgramsByCompany,
        this.companyObject.otherSelectedPrograms
      );
      this.isVisible = false;
      this.setPreserveMessageAfterPush(true);
      this.close();
      if (this.$route.path.includes("did-you-know-emails")) {
        window.location.reload();
      }
    },
  },
  computed: {
    dropdownLimit() {
      if (this.selectedProgramsByCompany.length > 1) {
        var programLabels = "";

        // Full program names for DMEPOS and CDNC are too long to show more than one program if they're selected first or second
        if (
          this.selectedProgramsByCompany[0].programDescription.length > 55 ||
          this.selectedProgramsByCompany[1].programDescription.length > 55
        ) {
          return 1;
        }

        for (var i = 0; i < this.selectedProgramsByCompany.length; i++) {
          programLabels += this.selectedProgramsByCompany[i].dropdownLabel;
        }

        if (programLabels.length > 75) {
          return 2;
        }
      }

      return 3;
    },
  },
};
</script>
