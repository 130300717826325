import Vue from "vue";

export const store = Vue.observable({
  isLoginOpen: true,
  isResendVerificationEmailOpen: false,
  isForgotPasswordOpen: false,
});

export const mutations = {
  toggleLogin() {
    store.isLoginOpen = !store.isLoginOpen;
  },
  toggleResendVerificationEmail() {
    store.isResendVerificationEmailOpen = !store.isResendVerificationEmailOpen;
  },
  toggleForgotPassword() {
    store.isForgotPasswordOpen = !store.isForgotPasswordOpen;
  },
  reset() {
    store.isLoginOpen = true;
    store.isResendVerificationEmailOpen = false;
    store.isForgotPasswordOpen = false;
  },
  showLogin() {
    store.isLoginOpen = true;
    store.isResendVerificationEmailOpen = false;
    store.isForgotPasswordOpen = false;
  },
};
