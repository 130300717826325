<template>
  <header class="page-header-second maincolumn">
    <b-img
      :src="require('../../assets/img/achclogonavigator.svg')"
      fluid
      alt="ACHC Logo"
      width="187"
    ></b-img>
    <b-navbar>
      <b-navbar-nav class="secondnav ml-auto my-auto">
        <b-nav-item class="secondbar" href="https://www.achc.org"
          ><p class="secondbartext">ACHC HOME</p></b-nav-item
        >
        <b-nav-item class="secondbar" v-scroll-to="'#compasswrapper'"
          ><p class="secondbartext">ACHC COMPASS</p></b-nav-item
        >
        <b-nav-item class="secondbar" href="#" v-scroll-to="{ el: '#customeriquiry', offset: -60 }"
          ><p class="secondbartext">CREATE ACCOUNT</p></b-nav-item
        >
        <b-nav-item class="secondbar" href="https://www.achc.org"
          ><p class="secondbartext">CONTACT</p></b-nav-item
        >
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>
<style scoped>
p {
  margin: 0;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import { adminRoot } from "../../constants/config";

export default {
  components: {},
  data() {
    return {
      adminRoot,
    };
  },
  methods: {
    ...mapActions(["signOut"]),

    async logout() {
      await this.signOut().then(() => {
        this.$router.push("/user/loginAD");
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
  },
  beforeDestroy() {},
  created() {},
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
  },
};
</script>
