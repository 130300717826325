<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>
<script>
// CSS Styles
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "../../assets/css/sass/main.scss";

// Our standard user account layout
import AppLayout from "../../layouts/AppLayout.vue";

export default {
  components: {
    "app-layout": AppLayout,
  },
};
</script>
