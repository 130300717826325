<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";
export default {
  data() {
    return {
      statusCode: null,
      statusMessage: null,
      processing: false,
      leadReferralTypes: [],
      leadTypes: [],
      timeframes: [],
      accountAdvisor: {},
      companyInfo: {},
      companyDetails: [],
      selectedPrograms: [],
      companyObject: {},
      dataChangeDetails: [],
      branchLocations: [],
      taxMask: "##-#######",
      selectedProgramsByCompany: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeBranchLocations",
      "changeCompanyContacts",
      "changeAdditionalSites",
      "setPreserveMessageAfterPush",
      "setShowInfoMessage",
      "setShowWarningMessage",
      "changeCompany",
      "changeRequest",
    ]),
    async getChangeRequestTable(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/ChangeRequest/table/${companyID}`)
        .then((result) => {
          this.changeRequest(result.data.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getChangeRequestByID(changeRequestID) {
      await axios
        .get(`${WebAPIUrl}/api/ChangeRequest/${changeRequestID}`)
        .then((result) => {
          this.dataChangeDetails = result.data.changeRequestFields;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCompanyByID(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company/${companyID}`)
        .then((result) => {
          this.changeCompany(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCompanyObjectByID(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/Company/${companyID}`)
        .then((result) => {
          this.companyObject = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    // get company
    async getCompanyName(userID) {
      return await axios
        .get(`${WebAPIUrl}/api/authorization/${userID}/company`)
        .then((result) => {
          this.companyDetails = result.data;
          //this.companyDetails.length = 1;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async addCompanyToASPNetUser(userID, companyID) {
      await axios
        .post(`${WebAPIUrl}/api/authorization/${userID}/AddCompany/${companyID}`)
        .then((result) => {
          this.statusCode = result.status;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    //Get Current Account Advisor Details
    async getAdvisorDetails(AzureGUID) {
      return await axios
        .get(`${WebAPIUrl}/api/company/advisor`, {
          params: { AzureGUID: AzureGUID },
        })
        .then((result) => {
          this.accountAdvisor = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    //
    async getLeadTypes() {
      await axios
        .get(`${WebAPIUrl}/api/lead-referential?tableName=LeadType`)
        .then((result) => {
          this.leadTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getLeadReferralTypes() {
      await axios
        .get(`${WebAPIUrl}/api/lead-referential?tableName=LeadReferralType`)
        .then((result) => {
          this.leadReferralTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getTimeframes() {
      await axios
        .get(`${WebAPIUrl}/api/lead-referential?tableName=AccreditationCompletedBy`)
        .then((result) => {
          this.timeframes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    //program selector
    async getSelectedPrograms(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company/selected-programs/${companyID}`)
        .then((result) => {
          this.selectedPrograms = result.data.selectedPrograms;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    // Program selector by Company
    async getSelectedProgramsByCompany(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company/program-selector-selected-programs/by-company/${companyID}`)
        .then((result) => {
          this.selectedProgramsByCompany = result.data.selectedPrograms;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    //
    async submitLeadInquiryForm(customerInquiryForm) {
      this.processing = true;
      await instance
        .post(`${WebAPIUrl}/api/company/inquiry-form`, customerInquiryForm)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            var companyID = result.data.dto.companyIDEncrypted;
            this.statusMessage = result.data.message;
            localStorage.setItem("CompanyID", companyID);
          }
        })
        .catch((error) => {
          this.messageBox(error, "Error");
        });
      this.processing = false;
    },
    messageBox(msg, title) {
      const htmlElement = this.$createElement;
      const message = htmlElement("p", [msg]);
      this.$bvModal.msgBoxOk([message], {
        title: title,
        buttonSize: "sm",
        centered: true,
      });
    },
    async quickAddNewCompany(company) {
      //company.CreatedByID = localStorage.getItem("employeeID");
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
      await instance
        .post(
          `
        ${WebAPIUrl}/api/company/${currentCompany.aspNetUserID}/quick-add-navigator`,
          company
        )
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            const { companyID = "", companyLegalName = "" } = result.data;
            company.companyID = companyID;
            this.setInfoMessage(`Company ${companyID} : ${companyLegalName} successfully created!`);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getDashboardAdditionalSites(parentCompanyID, withParent) {
      await axios
        .get(
          `${WebAPIUrl}/api/company/${parentCompanyID}/branch-locations?withParent=${withParent}`
        )
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.changeAdditionalSites(result.data);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getContactsAllByCompanyId(companyId) {
      await axios
        .get(`${WebAPIUrl}/api/company-contact/table/${companyId}/all`)
        .then((result) => {
          const { data = [] } = result.data;
          this.changeCompanyContacts(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async getCompanyInfo(companyID, applicationCompanyID = undefined) {
      const options = {
        url: `${WebAPIUrl}/api/company/${companyID}/info`,
        method: "GET",
        params: {
          applicationCompanyID: applicationCompanyID,
        },
      };
      await axios(options)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.companyInfo = result.data;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateCompanyInfo(companyInfo) {
      companyInfo.federalIDNumber = this.removeTaxMask(companyInfo.maskedFederalIDNumber);
      this.processing = true;
      await axios
        .put(`${WebAPIUrl}/api/company/company-info`, companyInfo)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            if (companyInfo.applicationCompanyID) {
              this.setInfoMessage(result.data);
            } else {
              this.setInfoMessage("Company saved successfully.");
            }
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
      this.processing = false;
    },
    async updateSelectedPrograms(companyID, selectedPrograms, otherSelectedProgram) {
      this.processing = true;
      var requestString = "";
      if (otherSelectedProgram === null || otherSelectedProgram === undefined) {
        requestString = `${WebAPIUrl}/api/company/program-selector-selected-programs/by-company/${companyID}`;
      } else {
        requestString = `${WebAPIUrl}/api/company/program-selector-selected-programs/by-company/${companyID}?otherSelectedProgram=${otherSelectedProgram}`;
      }
      await axios
        .put(requestString, selectedPrograms, otherSelectedProgram)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("Programs updated successfully!");
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
      this.processing = false;
    },
    async updateProgramsOfInterest(companyID, selectedPrograms) {
      await axios
        .put(`${WebAPIUrl}/api/company/programs-of-interest/${companyID}`, selectedPrograms)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("Programs updated successfully!");
        });
    },
    async updateApplyReason(changeRequestID, appliedDeniedReason) {
      await axios
        .put(`${WebAPIUrl}/api/ChangeRequest/apply/${changeRequestID}`, appliedDeniedReason)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("Applied Reason updated successfully!");
        });
    },
    async updateDenyReason(changeRequestID, appliedDeniedReason) {
      await axios
        .put(`${WebAPIUrl}/api/ChangeRequest/deny/${changeRequestID}`, appliedDeniedReason)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("Denied Reason updated successfully!");
        });
    },
    async activateCompany(payload) {
      await axios.put(`${WebAPIUrl}/api/company/activate`, payload).then((result) => {
        this.statusCode = result.status;
        this.setInfoMessage(result.data);
      });
    },
    async getBranchLocations(parentCompanyId) {
      await axios
        .get(`${WebAPIUrl}/api/company/${parentCompanyId}/branch-locations?withParent=true`)
        .then((result) => {
          this.branchLocations = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    removeTaxMask(maskNumber) {
      const number = maskNumber ? maskNumber.replace(/[^0-9]/g, "") : null;
      return number;
    },
  },
};
</script>
