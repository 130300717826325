<script>
import axios from "axios";
import { WebAPIUrl, CustomerServiceEmail } from "../constants/config";
import { mapMutations } from "vuex";
//import _ from "lodash";

export default {
  data() {
    return {
      passwordRequirements: "",
      processing: false,
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setModalDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getPasswordRequirements() {
      await axios
        .get(`${WebAPIUrl}/api/applicationuser/password-requirements`)
        .then((result) => {
          var requirements = "<ul><li>" + result.data.join("</li><li>") + "</li></ul>";
          this.passwordRequirements =
            "Password must meet the following requirements: " + requirements;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async register(user, companyID) {
      this.processing = true;
      // If it's null then that means they didn't sign up thru the
      // user request button on the leads dashboard
      if (companyID === null) {
        // Grab companyID from inquiry form
        companyID = localStorage.getItem("CompanyID");
      }

      user.companyID = companyID;
      var companyIDQuery = companyID !== null ? `?companyID=${companyID}` : "";
      await axios
        .post(`${WebAPIUrl}/api/applicationuser/register${companyIDQuery}`, user)
        .then((result) => {
          this.statusCode = result.status;
          //this.setInfoMessage(` Login successfully !`);
        })
        .catch((error) => {
          this.setModalDangerMessage(
            `${error.response.data}. For support, call (855) 937-2242 or send an email to ${CustomerServiceEmail}`
          );
        });
      this.processing = false;
    },
  },
  async created() {},
};
</script>
